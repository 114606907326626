import React from 'react';
import PropTypes from 'prop-types';
import FullScreenVideoPlayer from 'components/FullScreenVideoPlayer';
import {AppSettings} from './Layout';

import scene from 'components/scene';
import 'styles/components/video-introduction.scss';

const textStates = {
  'VISIBLE': 'visible',
}

const VideoIntroduction = class extends React.Component {
  static contextType = AppSettings
  constructor(props) {
    super(props)
    this.handleVideoUpdate = this.handleVideoUpdate.bind(this)
  }

  componentWillUnmount () {
    if (this.videoAnimationFrame) {
      cancelAnimationFrame(this.videoAnimationFrame)
    }
  }

  componentDidMount () {
    const textRefs = document.querySelectorAll('.video-introduction__text')
    this.textRefs = Array.prototype.slice.call(textRefs)
  }

  handleVideoLoadedMetaData (player) {
    this.video = player

    this.handleVideoUpdate()
  }

  handleVideoUpdate () {
    const { texts } = this.props

    if (this.videoAnimationFrame) {
      this.videoAnimationFrame = null
    }

    const {currentTime, duration} = this.video
    const textsToHide = []
    const textsToShow = []

    this.textRefs.forEach((textNode, index) => {
      const {enter, leave, hasEntered, hasLeft} = textNode.dataset

      if (currentTime > enter && !textNode.classList.contains(textStates.VISIBLE)) {
        textNode.classList.add(textStates.VISIBLE)
      }

      if (currentTime > leave && textNode.classList.contains(textStates.VISIBLE)) {
        textNode.classList.remove(textStates.VISIBLE)
      }
    })

    this.videoAnimationFrame = requestAnimationFrame(this.handleVideoUpdate)

  }

  handleVideoComplete(e) {
    this.props.actions.moveContentForwards()
  }

  render() {
    const { position, src, texts } = this.props
    return (
      <div className="video-introduction">
        <FullScreenVideoPlayer
          key={this.context.displayChangeMessage}
          src={src}
          onVideoLoadedMetaData={texts ? this.handleVideoLoadedMetaData.bind(this) : null}
          onVideoEnded={this.handleVideoComplete.bind(this)}
          muted
          playsinline
          autoplay={!this.context.displayChangeMessage} />

        <div className="video-introduction__texts">
          {texts && texts.map(({text, attribution, speed, time, horzAlignment, vertAlignment}, index) => {
            const horzAlignString = horzAlignment ? 'horizontal-align-' + horzAlignment : ''
            const vertAlignString = vertAlignment ? 'vertical-align-' + vertAlignment : ''
            const speedString = speed || ''
            const classes = `video-introduction__text ${speedString} ${horzAlignString} ${vertAlignString}`

            return (
              <div
                key={index}
                className={classes}
                data-enter={time.enter}
                data-leave={time.leave}
              >
                <p className="video-introduction__text__words">
                  {text.split(' ').map((word, index, array) => <span key={index} dangerouslySetInnerHTML={{__html: index === array.length - 1 ? word : word + ' '}} />)}
                </p>
                {attribution &&
                  <div className="video-introduction__text__attribution">
                    <p className="name">
                      {attribution.name}
                    </p>
                    <p className="title">
                      {attribution.title}
                    </p>
                  </div>
                }
              </div>
            )
          })}
        </div>
      </div>
    );
  }
};

VideoIntroduction.propTypes = {

};

export default scene(VideoIntroduction);
