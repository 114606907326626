import React from 'react';
import Helmet from 'react-helmet';

import ChapterPage from 'components/chapter';

import IntroductionVideo from '../components/scenes/introduction/introduction-video/IntroductionVideo';
import IntroS1 from '../components/scenes/introduction/scene-1/IntroductionScene1';
import IntroS2 from '../components/scenes/introduction/scene-2/IntroductionScene2';
import IntroS3 from '../components/scenes/introduction/scene-3/IntroductionScene3';
import IntroS4 from '../components/scenes/introduction/scene-4/IntroductionScene4';
import IntroS5 from '../components/scenes/introduction/scene-5/IntroductionScene5';

class Introduction extends React.Component {

  render() {
    const {SceneComponent} = this.props;

    const title = 'Selma: Introduction'
    return (
      <div className="introduction">
          <ChapterPage
            title={title}
            scenes={[
              <IntroductionVideo />,
              <IntroS1 />,
              <IntroS2 />,
              <IntroS3 />,
              <IntroS4 />,
              <IntroS5 theme="dark" />
            ]}
          />
      </div>
    );
  }
}

export default Introduction;
