import React from 'react';
import {uniqueId} from 'lodash';
import PropTypes from 'prop-types';
import TweenMax, {Expo} from 'gsap/TweenMax';
import CSSPlugin from 'gsap/CSSPlugin'; // eslint-disable-line no-unused-vars
import FullScreenContainer from 'components/FullScreenContainer';
import Timeline from 'components/timelineComponent';
import {
  placeholder, hideAllRefs, showElemWithAria, hideElementWithAria, hideFromBelow, revealFromBelow
} from 'utils/animation';
import {tabbable} from 'utils/accessibility'

import 'styles/components/clip-and-zoom-map.scss';

const viewBoxWidth = 1138.9;
const viewBoxHeight = 850.4;
const viewBoxAspectRatio = viewBoxHeight / viewBoxWidth;

const aspectRatio = 0.45;

const ClipAndZoomMap = class extends React.Component {
  constructor(props) {
    super(props);
    this.id = uniqueId('mapZoom_');
  }

  componentDidMount() {
    const {state, zoomAdjust} = this.props;
    const {states} = this.refs;
    const isDc = state === 'washington-dc'

    const stateSvg = isDc ? this.dcState : states.querySelector(`.${state}`);

    const clonedSvg = stateSvg.cloneNode(true);
    this.refs.mask.appendChild(clonedSvg);

    stateSvg.setAttribute('data-zoom-target', true);

    if (zoomAdjust) {
      stateSvg.setAttribute('data-zoom-adjust', zoomAdjust);
    }

    stateSvg.parentNode.appendChild(stateSvg);
  }
  render() {
    const {image, state, title, startOnMap} = this.props;
    const isDc = state === 'washington-dc'

    return (
      <FullScreenContainer className="clip-and-zoom-map">
        <div className="clip-and-zoom-map__svgContainer" data-start-on-map={startOnMap} ref="mapSvgContainer">
          <svg viewBox="0 0 100% 100%">
            <image ref="maskedImage" width="100%" height="100%" preserveAspectRatio="xMidYMid slice" xlinkHref={image} />
          </svg>
          <svg ref="mapSvg" className="clip-and-zoom-map__svg" width="100%" height="100%" version="1.1" xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink"
            viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`} xmlSpace="preserve">
            <defs>
              <mask id={this.id + 'Mask'}>
                <rect ref="backgroundMask" fill="white" />

                <g ref="mask" fill="black">

                </g>
              </mask>
            </defs>

            <rect ref="background" mask={`url(#${this.id + 'Mask'})`} fill="black" />
            <g ref="mapMovement">

              <g className="map" ref="map">
                {isDc &&
                  <g className="states">
                    <g ref={ref => this.dcState = ref} className="state washington-dc">
                      <polygon className="state__path" points="1007.6,262.5 997,251.8 997,251.8 997,251.8 996.3,251.1 996.2,251 996.2,251 996.1,250.9 990.2,245
                        988.9,243.7 988.9,243.7 988.7,243.5 981.8,236.6 978.7,233.5 978.6,233.4 978.6,233.4 978.5,233.3 974,228.7 967.2,221.7
                        967.2,221.7 967.2,221.7 966.8,221.3 966.6,221.2 966.6,221.2 966.5,221 963,217.7 963,217.7 963,217.7 961.6,216.4 961.5,216.3
                        961.3,216.4 957.9,219.7 957.8,219.7 957.8,219.7 957.8,219.7 957.7,219.8 957.7,219.8 957.7,219.8 950.5,227 950.4,227.1
                        950.3,227.2 945.6,231.9 945.3,232.2 943.7,233.8 943.7,233.8 943.7,233.8 943.6,233.9 943.5,234 943.5,234 943.5,234 937.1,240.4
                        937,240.5 937,240.5 936.9,240.6 934.6,243 934.4,243.1 934.5,243.2 939.5,250.9 939.7,251.8 939.7,251.8 939.7,251.8 939.9,253.4
                        939.9,253.5 940,253.6 940.6,254.2 940.6,254.2 940.6,254.2 942,255.4 942.9,256.1 943,256.2 943,256.2 945,257 946.8,257.7
                        946.8,257.8 946.9,257.8 948,257.8 948.3,257.8 948.3,257.8 948.7,257.9 951.3,258 953,258.6 955.4,259.8 955.5,259.9 955.5,259.9
                        955.5,260 955.5,260 955.9,262.2 955.9,262.5 956,262.7 956.1,262.7 957.5,263.2 957.5,263.2 957.5,263.2 961.7,263.4 961.8,263.4
                        961.8,263.4 962,263.4 970.5,263.4 970.6,263.5 971,264.6 971,264.8 971,264.8 962.1,264.9 961.8,264.9 962,265.2 963.7,267.8
                        963.8,267.8 963.9,267.8 965.5,267.8 967.6,270.3 967.9,270.8 968.6,272.1 968.8,277.1 968.8,277.2 968.9,277.3 969.4,277.6
                        969.4,277.7 969.5,277.7 969.7,277.7 969.8,277.7 969.8,277.6 970.6,277.2 970.7,277.1 970.7,277 970.7,276.3 971.1,276.2
                        971.7,276.2 971.8,276.2 971.9,276.1 972.4,275.5 972.4,275.5 972.4,275.5 972.7,274.8 972.7,274.8 972.7,274.8 972.9,274.4
                        973.8,272.6 976.3,272 976.5,272 976.5,271.9 976.5,270.5 978.4,270.3 978.5,271.1 978.5,271.1 978.5,271.1 978.7,272.1
                        978.8,272.4 979,272.3 981.3,270.9 981.5,270.8 981.6,270.7 981.6,270.7 981.8,270.7 982.8,270.2 984.5,269.6 984.5,269.6
                        984.6,269.5 985.2,269 985.3,269 985.3,269 986.1,267.4 986.1,267.4 986.1,267.4 987.3,264.5 987.3,264.4 987.3,264.4 987.4,263.7
                        987.4,263.6 987.4,263.6 986.9,262.6 987.1,262.5 987.2,262.4 987.2,262.3 987.2,262.1 987.7,261.5 987.8,261.4 987.8,261.4
                        987.8,260.2 987.8,260.1 987.8,260.1 987.7,259.8 988,258.1 988,258.1 988,258 988.8,257.4 988.8,257.4 988.8,257.3 989,256.8
                        989.7,256.1 990,256 990.4,256.3 990.6,256.4 990.7,256.2 990.9,256 990.6,256.6 989.9,258.3 989.4,258.9 989.3,258.9 989.3,259
                        989.1,259.9 989.1,259.9 989.1,260 989.3,260.7 988.7,264 988.7,264 988.7,264 988.7,264.2 988.2,265.5 986.1,267.7 986.1,267.7
                        986.1,267.8 985.8,268.6 985.1,269.4 982.9,270.3 982.9,270.3 982.9,270.3 980.5,271.6 980.5,271.6 980.4,271.6 977.8,273.4
                        977.5,273.4 975.2,272.7 975.1,272.7 975,272.8 974.1,273.7 974,273.8 974.1,274 974.4,274.6 974.4,274.9 973.9,277.3 972.5,281.5
                        972.5,281.5 972.5,281.6 972.1,286.6 969.3,291.1 969.3,291.2 969.3,291.2 969.3,294.7 966.1,294.9 966,294.9 965.9,295.1
                        965.8,297.4 965.8,297.5 965.8,297.5 966.6,299.8 966.7,299.9 966.8,299.9 968.1,300.2 968.1,300.2 968.1,300.2 970.4,300.3
                        970.5,300.3 970.5,300.2 975.2,295.5 990.1,280.6 1001.9,268.7 1006.1,264.4 1006.4,264.1 1007.7,262.8 1007.8,262.7 	"/>
                    </g>
                  </g>
                }
                <g ref="states" className="states">
                  <g className="state wyoming">
                  	<path className="state__path" d="M430.4,295.2l6.9-96.3c-15.2-0.5-96.6-9.2-117.3-12.8l-1.6,10.6l-9.2,61.6l-3.5,23.5
                  		C336.6,286.7,382,291.7,430.4,295.2z"/>
                  </g>
                  <g className="state west_virginia">
                  	<path className="state__path" d="M847.2,351.2l7,6.8l6.2-5.5l4.5,1.4l14.3-9.4l7.4-25.3l7.5,1l2.7-9.6l4.8,0l9.8-14.1l4.6,2.1l2.9-8.3l-7.9-3
                  		l-6.8,5.5l-6.4-1.9l-11.2,11.4l-2.7-14.9c-7.2,1.7-13.8,3.2-19,4.2l-2.1-10.4l-5.7,2.7l0,14.8l-3.4,6.1l-5.2,1.1l-2.6,11.1l-5.4-1
                  		l-1,9.8l-6.6,6.4l3.4,14.5L847.2,351.2z"/>
                  </g>
                  <g className="state washington">
                  	<g>
                  		<path className="state__path" d="M145.2,63l3.1,0.3c1.1,0.1,2.1-0.7,2.2-1.9l0.2-2.7c0.1-1.1-0.7-2.3-1.8-2.6l-2.4-0.7
                  			c-1.1-0.3-2.1,0.4-2.3,1.5l-0.7,3.9C143.4,61.9,144.1,62.9,145.2,63z"/>
                  		<path className="state__path" d="M136.8,132.1l6.1-0.5l2.5,3.9h15.2l15.1,6.1l18.7-0.7l2.9-3.4l32.4,9.6l-0.4-8.6l14.7-61.2l0,0
                  			c-28.1-6.3-58-13.8-87.3-22v0l-1.9,5.6c-0.2,1,0.5,1.7,1.5,1.6l0.7-0.1c1-0.1,1.8,0.6,1.8,1.6v1.7c0,1-0.8,2-1.8,2.2l-0.3,0.1
                  			c-1,0.2-1.7,1.2-1.5,2.2l-0.1,3.7c0.1,0.8,0.9,1.3,1.6,1l1-0.3c0.8-0.3,1.1-0.2,1.3,0.6l0.3,1.1c0.2,0.8,0,2-0.5,2.6l-3.1,5.5
                  			c-0.5,0.6-1.5,1.4-2.3,1.7l-3.2,2.1c-0.8,0.3-1,0-1-0.9v-0.7c0-0.8,0.4-2,1-2.6l2.3-2.1c0.5-0.6,0.4-2.1-0.4-1.9l-4,0.8
                  			c-0.8,0.2-1.4-0.4-1.3-1.2l0.1-0.3c0.1-0.8,0.8-1.7,1.6-1.9l2.8-0.8c0.8-0.2,1.3-1.1,1.2-1.9l-0.9-2c-0.1-1-1.1-1.8-2.1-1.7
                  			l-2,0.1c-1,0.1-2.5-0.4-3.2-1.1l-8.2-6.7c-0.9-0.7-5.5-2.2-6.4-2.3c-0.9-0.1-1.9,0.7-2.2,1.7l-2,5.2c-0.3,1.1-0.4,2.9-0.3,4
                  			l0.9,8.4c0.1,1.1-0.1,2.9-0.5,3.9l-2.3,6.3c-0.4,1.1-0.3,2.7,0.2,3.7c0.5,1,1.6,1.2,2.5,0.5l2.6-2c0.9-0.7,1.5-0.3,1.4,0.8
                  			l-0.3,3.4c-0.1,1.1-0.9,2.6-1.7,3.3l-2.2,1.9c-0.8,0.7-1.8,2.2-2.2,3.3l-0.1,0.3c-0.3,1.1,0.2,2.4,1.1,2.9l3,1.7
                  			c1,0.5,1.5,1.9,1.2,2.9l-0.2,0.7l7.8,5.8L136.8,132.1z"/>
                  	</g>
                  </g>
                  <g className="state wisconsin">
                  	<path className="state__path" d="M722.3,251.3l0.2-4.4c0.1-1.1-0.3-2.9-0.7-3.9l-2.6-5.8c-0.5-1-0.5-2.7-0.1-3.8l2.9-8c0.4-1.1,0.3-2.7-0.2-3.7
                  		l-0.4-0.9c-0.5-1-0.5-2.6-0.1-3.7l1.7-3.8c0.5-1,0.5-2.6,0.2-3.6c-0.4-0.9-0.2-2.5,0.4-3.4l3.6-5.5c0.6-0.9,1.3-2.6,1.5-3.7
                  		l0.7-4.2c0.2-1.1,0.7-2.9,1.1-3.9l0.7-1.7c0.4-1,0-2.5-0.8-3.3l-0.2-0.2c-0.8-0.8-2.1-0.7-2.9,0.1l-2.1,2.3
                  		c-0.8,0.8-1.6,2.4-1.8,3.5l-0.9,4.7c-0.2,1.1-1.1,2.6-1.9,3.3l-6.4,5.6c-0.8,0.7-1.5,0.4-1.5-0.7v-6c0-1.1,0.8-2.5,1.8-3l0.3-0.1
                  		c1-0.5,1.9-1.9,2-3l0.1-1.1c0.1-0.6,0.2-1.3,0.5-2.1l0,0l-5.5-3.5l1.9-6.6l-2.5-3.6l-3.5,0.9l-4.1-6l-8.8,3l-20.9-4.3l-7-6.9l0,0
                  		c-0.2,0-0.3,0-0.5-0.1l-1.6-0.4c-1.1-0.3-2.9-0.5-4-0.4l-0.2,0c-1.1,0.1-1.8-0.8-1.5-1.9l0.9-3.2c0.3-1.1-0.3-2.3-1.3-2.7l-1.3-0.5
                  		c-1-0.4-2.4,0-3,0.9l-1,1.5c-0.6,0.9-1.9,2.2-2.9,2.8l-5.4,3.4c-0.9,0.6-2.6,0.8-3.6,0.4l-1.3-0.4l-5.1,4.9l0.5,11.3l-6.6,5
                  		l-2.1,6.6l4.4,3.6l-1.7,13.9l15.4,7l2.1,4.9l10.6,7.7l5.2,14l-2.8,4.4l3.9,8.8l6.5,2.4l2.8,2.9L722.3,251.3L722.3,251.3z"/>
                  </g>
                  <g className="state virginia">
                  	<g>
                  		<path className="state__path" d="M961.5,313L961.5,313c0.3,0.6,0.6,1.4,0.5,2l-1.1,14.3c-0.1,1.1,0.7,2,1.9,1.9l2.1-0.1c1.1-0.1,2.2-1,2.4-2.1
                  			l1.1-5.8c0.2-1.1,0.8-2.8,1.2-3.8l2.7-5.7c0.5-1,0.9-2.8,1.1-3.9l0.1-0.7l0,0L961.5,313z"/>
                  		<path className="state__path" d="M818.5,380.1c61.8-10.7,112.3-24.9,152.6-35.7l-5.3-5.7c-0.8-0.8-2.1-1-3.1-0.4l-6.1,4.1
                  			c-0.9,0.6-2.5,0.7-3.5,0.2l-3.1-1.7c-1-0.5-2.1-1.8-2.4-2.9l-0.6-1.8c-0.3-1.1,0.3-2,1.4-2l4.5-0.1c1.1,0,1.5-0.8,0.9-1.8
                  			l-0.4-0.6c-0.6-0.9-0.7-2.2-0.1-2.7c0.5-0.6,0.5-1.8-0.2-2.7l-0.8-1.2c-0.6-0.9-0.9-2.6-0.6-3.6l0.4-1.5c0.3-1.1-0.2-2.4-1.2-3
                  			l-2.1-1.3c-0.6-0.3-0.8-0.8-0.6-1.3l-11.4-3.2l-5.4,2.3l-2.7-2.8l3.9-6.6l-1.3-6.7l-7.3-0.6v-4.3l-5.6-2.1l-2.9,8.3l-4.6-2.1
                  			l-9.8,14.1l-4.8,0l-2.7,9.6l-7.5-1l-7.4,25.3l-14.3,9.4l-4.5-1.4l-6.2,5.5l-7-6.8L818.5,380.1L818.5,380.1z"/>
                  	</g>
                  </g>
                  <g className="state vermont">
                  	<path className="state__path" d="M982.3,128.6l-25.8,8.3l0,0.1l5.9,17.1l-1.1,6.8l3.4,8.2l4.5,0.5l5.4,19.6l10.7-3.6l1.1-6.1l-3.9-12.9l2.8-9
                  		l-2.9-9.3l5.8-10.1l-4-11l0,0C983.6,127.9,982.9,128.4,982.3,128.6z"/>
                  </g>
                  <g className="state utah">
                  	<path className="state__path" d="M340.7,286.6c-13-1.6-24.8-3.2-35-4.8l3.5-23.5c-14.7-2.3-32.2-5.3-51.2-8.9L238.6,367
                  		c29.9,5.8,60.4,10.5,91,14.4L340.7,286.6L340.7,286.6z"/>
                  </g>
                  <g className="state texas">
                  	<path className="state__path" d="M633.2,531.7l-7-7.9v-6.3l-5.3-8.9l-0.9-35.8l-8.8,1.8l-15.6-9.1l-21.2,7.4l-10-5.9l-6.4,5.1l-7.3-6.5
                  		l-7.2,3.1l-5.3-6.8l-13.2,0.3l-9.8-7.4l-5.2,2.1l-9.5-6.4l1-41.9c-19.2-0.4-38.7-1.2-58.9-2.4l-0.1-0.1l-5.6,103.6
                  		c-20.9-1-51.1-3.4-70.3-5.3l-0.5,6.4c0.6,0.3,1.1,0.7,1.4,1l4.2,4.6c0.7,0.8,2.2,1.6,3.3,1.7s2.6,0.8,3.4,1.6l17.2,16.3
                  		c0.8,0.8,1.7,2.3,2,3.4l4.4,17.7c0.3,1.1,1.2,2.5,2.2,3.1l9.3,6.2c0.9,0.6,2.2,1.9,2.8,2.8l1.1,1.6c0.6,0.9,1.9,2.2,2.9,2.7
                  		l7.1,4.3c1,0.6,2.5,0.5,3.3-0.2l2.8-2.2c0.9-0.7,2-2.1,2.4-3.1l2.7-6c0.5-1,1.7-2.1,2.8-2.4l3.8-1.2c1.1-0.3,2.5-1.3,3.1-2.3l0.7-1
                  		c0.6-0.9,2-1.4,3.1-1l4.8,1.6c1.1,0.4,2.8,0.5,3.9,0.4l3.8-0.5c1.1-0.2,2.5,0.5,3,1.5l1.4,2.5c0.6,1,1.9,2.1,2.9,2.4l0.4,0.1
                  		c1.1,0.4,2.4,1.4,3,2.4l0.4,0.7c0.6,1,1.6,2.5,2.2,3.4l3,4.3c0.6,0.9,1.5,2.5,1.9,3.6l5.9,15.4c0.4,1,1.5,2.4,2.5,3l1,0.6
                  		c1,0.6,2.2,1.9,2.7,2.8l4.5,8.2c0.5,1,1.8,2.2,2.8,2.8l2.4,1.3c1,0.5,1.6,1.9,1.4,3l-0.3,1.5c-0.2,1.1-0.1,2.9,0.2,3.9l6.2,18.2
                  		c0.4,1.1,1.5,2.3,2.5,2.7l18.4,7.4c1,0.4,2.8,0.6,3.9,0.4l2.5-0.5c1.1-0.2,2.8,0.1,3.8,0.6l7.8,4.4c1,0.5,2.5,0.5,3.5-0.1l3.8-2.5
                  		c0.9-0.6,1.5-2,1.4-3.1l-3.6-21.4c-0.2-1.1-0.1-2.9,0.1-4l2.3-9.8c0.3-1.1,1-2.7,1.7-3.6l8.7-10.7c0.7-0.9,2.1-2,3.1-2.5l20-10.2
                  		c1-0.5,2.5-1.5,3.4-2.2L611,573c0.9-0.7,2.4-1.7,3.4-2.2l13-6.1l0,0l2.9-12.6l-1.9-4.9L633.2,531.7z M555.2,613.6l-3.7,15.6
                  		c-0.3,1.1-0.6,1.1-0.7,0l-1.4-10.5c-0.1-1.1,0.3-2.4,1-3l3.9-3.1C555.1,612.1,555.5,612.5,555.2,613.6z M575.9,593l-6.8,3.4
                  		c-0.7,0.4-1.7,1.1-2.3,1.7l-4.9,5.4c-0.5,0.6-1,1.1-1.1,1.1c-0.1,0,0-0.6,0.2-1.4l1.6-5.9c0.2-0.8,0.9-1.8,1.5-2.3l3.2-2.6
                  		c0.6-0.5,1.8-0.8,2.6-0.8l5.9,0.5C576.5,592.3,576.6,592.6,575.9,593z M607.5,571.7l-2.9,2.3c-0.6,0.5-1.5,0.4-2-0.1l-1.8-1.8
                  		c-0.5-0.5-0.4-1.3,0.1-1.8l5.7-4.2c0.6-0.4,1.2-0.2,1.3,0.5l0.4,3C608.4,570.3,608,571.2,607.5,571.7z"/>
                  </g>
                  <g className="state tennessee">
                  	<path className="state__path" d="M816.9,407.4l35.7-23.4l4.8-11.4c-36.2,7.6-76.9,14.3-122.5,18.5l-0.4,4.4l-25.5,1.7l-4.1,0.2l-4.9,19l2.2,4.3
                  		l-7.1,5.3l3.4,4.2l-4.4,4.9c26.4-2.2,81.8-10.4,117.1-16.1l0-0.1L816.9,407.4z"/>
                  </g>
                  <g className="state south_dakota">
                  	<path className="state__path" d="M531.3,250.8l9,7.5l7.4-4.4l10.2,3l4.3,7.1l6.9,0.5l-2.1-7.6l2.3-6.8l-5.2-5.4l2.8-8.7l-0.9-40.3l-1.3-14.3
                  		c-20.8,1-106.3-0.9-125.9-3l0,0l-1.5,20.5l-3.4,48.3C461.1,249,518.8,250.9,531.3,250.8z"/>
                  </g>
                  <g className="state south_carolina">
                  	<path className="state__path" d="M841.6,426.9l7.5,10.9l14.6,8.1l3.6,6.6l7.2,2.1l3,8.8l4.8,2.4l1.9,9.6l6.7,0.1l0,0l7.9-12.3
                  		c0.6-0.9,1.9-2.2,2.8-2.8l4.4-2.9c0.9-0.6,1.9-2,2-3.1l0.2-1.4c0.2-1.1,1-2.6,1.8-3.4l8.2-7.4c0.8-0.7,1.5-2.3,1.5-3.4v-5.2
                  		c0-1.1,0.6-2.7,1.3-3.6l5.9-6.8c0.4-0.5,1.1-1,1.8-1.4l-0.4-0.1L902.8,405l-21.2,4.3l-6.1-7.9l-28.4,5.7l-12.4,7.9l-3.6,7.7
                  		L841.6,426.9z"/>
                  </g>
                  <g className="state rhode_island">
                  	<g>
                  		<path className="state__path" d="M1028.1,214.3l-4.2-1.5c-0.8-0.3-1.7,0.2-2,1l-1.2,4.4c-0.2,0.9,0.2,1.2,1,0.8l6.5-3.4
                  			C1029,215.2,1029,214.6,1028.1,214.3z"/>
                  		<path className="state__path" d="M1017.6,201l-6.9-5.9l-7.5,2.5l5.4,15.7l2.1-0.9c1-0.4,1.9-1.7,1.8-2.8l0-2.3c0-1.1,0.6-1.4,1.3-0.5l1.4,1.6
                  			c0.7,0.8,1.9,0.8,2.6-0.1l1.6-2L1017.6,201z"/>
                  	</g>
                  </g>
                  <g className="state pennsylvania">
                  	<path className="state__path" d="M949.1,270.4l1.1-4.3l5.7,2.4l10-11.2l-11.7-10.3l5-17l0-0.2l-5.8-1.1l-3.2-6.2l-6.7-4
                  		c-23.1,7.6-76.7,20-76.7,20l-2.3-9.1v0c-0.5,0.3-1.1,0.5-1.5,0.7l-1.1,0.3c-1.1,0.3-2.3,1.4-2.8,2.4l-0.6,1.3
                  		c-0.5,1-1.7,2.2-2.7,2.7l-2,1l9,43.5l2.1,10.4C890.5,286.7,949.1,270.4,949.1,270.4z"/>
                  </g>
                  <g className="state oregon">
                  	<path className="state__path" d="M230,151.4l-0.2-4.3l-32.4-9.6l-2.9,3.4l-18.7,0.7l-15.1-6.1h-15.2l-2.5-3.9l-6.1,0.5l-0.4-12.1l-7.8-5.8
                  		l-0.7,2.2c-0.3,1.1-1.4,1.5-2.3,0.9l-2.7-1.6c-1-0.6-2-0.2-2.3,0.9l-4.1,13.3c-0.3,1.1-0.9,2.8-1.4,3.8l-12.8,31.5
                  		c-0.4,1-1.3,2.6-1.9,3.5l-10.6,15.2c-0.6,0.9-0.8,2.5-0.4,3.5l1.1,2.5c0.4,1,0.2,2.6-0.5,3.4l-2.1,2.4c-0.7,0.9-1.4,2.5-1.5,3.6
                  		l-0.2,2.2c-0.1,1.1,0.4,2.7,1.1,3.5l0.4,0.4c0.4,0.4,0.6,0.9,0.6,1.5c32.3,11.4,75.9,22.6,118.4,31.9l9.4-43.5l5-6.7l-1-4.5l-4-1.3
                  		l3.8-9l5.1-2.4l10.1-14.1l-0.9-4.8L230,151.4z"/>
                  </g>
                  <g className="state oklahoma">
                  	<path className="state__path" d="M605.9,392.8c-41.9,2-91.3,2.1-144-0.3c-6.2-0.3-12.5-0.6-18.8-1l-0.7,14.3l0.1,0.1
                  		c20.2,1.1,39.7,1.9,58.9,2.4l-1,41.9l9.5,6.4l5.2-2.1l9.8,7.4l13.2-0.3l5.3,6.8l7.2-3.1l7.3,6.5l6.4-5.1l10,5.9l21.2-7.4l15.6,9.1
                  		l-4.9-69L605.9,392.8z"/>
                  </g>
                  <g className="state ohio">
                  	<path className="state__path" d="M802.3,329L819,331l6.1-5.6l7.9,7l6.6-6.4l1-9.8l5.4,1l2.6-11.1l5.2-1.1l3.4-6.1l0-14.8l5.7-2.7l-9-43.5
                  		l-4.7,2.4c-1,0.5-2.4,1.6-3.1,2.5l-9.6,11.6c-0.7,0.9-2.2,1.6-3.3,1.6h-3.1c-1.1,0-2.8,0.5-3.7,1.1l-2.6,1.7
                  		c-0.9,0.6-2.5,0.7-3.5,0.2l-2.2-1.1c-1-0.5-2.6-0.4-3.5,0.2l-0.1,0c-0.9,0.6-2.6,0.8-3.6,0.5l-5-1.6c-0.5-0.2-1-0.5-1.3-1l0,0
                  		c-6,1.3-15.6,3-25.9,4.6l0,0.1l9.6,63l7.7-1.9L802.3,329z"/>
                  </g>
                  <g className="state new_mexico">
                  	<path className="state__path" d="M436.8,509.5l6.3-117.9l-0.1,0c-36.9-2.1-75.1-5.4-113.4-10.2l-15.4,133.1l15.4,2l2.1-10.3l32.2,3.7
                  		c0.6,0.1,1.3,0.3,2,0.7l0.5-6.4C385.7,506.1,415.9,508.5,436.8,509.5z"/>
                  </g>
                  <g className="state north_dakota">
                  	<path className="state__path" d="M564.7,181.4l-0.6-6.9l-4.2-8l-1.7-24.5l-5.1-10.6l2.3-11.1l-4.7-10.5l0,0c-37,0.2-72.4-0.8-106.8-3.1
                  		l-5.1,71.7l0,0C458.4,180.5,543.9,182.4,564.7,181.4z"/>
                  </g>
                  <g className="state north_carolina">
                  	<path className="state__path" d="M847,407.1l28.4-5.7l6.1,7.9l21.2-4.3l25.6,16.5l0.4,0.1c0.5-0.3,1-0.6,1.5-0.7l8.9-2.7
                  		c1.1-0.3,2.2-1.5,2.5-2.6l1.9-7.3c0.3-1.1,1.2-2.6,2-3.4l11.1-10.4c0.8-0.8,2.4-1.2,3.5-1.1l3.2,0.5c1.1,0.2,2.6-0.3,3.4-1.1l7.8-8
                  		c0.8-0.8,1.5-2.4,1.6-3.5l0.6-6.2c0.1-1.1-0.4-1.4-1.2-0.6l-5.9,2.7c-0.5,0.5-1.5,1.1-2.2,1.3l-1.9,0.5c-0.7,0.2-1.1-0.2-1-0.9
                  		l0.2-1c0.2-0.7,0-1.6-0.4-2.1c-0.3-0.5-0.1-0.9,0.7-1l4.3-0.4c0.7-0.1,1.5-0.8,1.7-1.4l0.4-1.2c0.2-0.7,1.3-2.4,2-2.8
                  		c0.6-0.3,1.1-1.5,0.9-2.6l-0.2-2.1c-0.1-1.1-1.1-2.1-2.3-2.3l-7.8-1.4c-0.7-0.1-0.8-0.5-0.3-1l1.9-1.5c0.6-0.4,1-1.4,1-2.1l0-1
                  		c0-0.7,0.4-0.9,1-0.4l8.4,5.2c0.8,0.8,1.3,0.5,1.1-0.6l-1.1-6.8c-0.2-1.1-0.9-2.7-1.7-3.5l-3.5-3.8c-31.6,8.4-69.3,18.9-113.8,28.2
                  		l-4.8,11.4l-35.7,23.4l-5.8,11.5l0,0.1c9.7-1.6,18-3,23.7-4L847,407.1z"/>
                  </g>
                  <g className="state new_york">
                  	<g>
                  		<path className="state__path" d="M980.2,243.7L980.2,243.7l5.8-1.7c1.1-0.3,2.8-0.9,3.8-1.4l10.1-4.4c1-0.4,2.5-1.4,3.3-2.2l4.6-4.4
                  			c0.8-0.8,1.5-2.3,1.5-3.4l0.1-2.8c0-1.1-0.7-1.6-1.8-1.1l-3.2,1.6c-1,0.5-2.3,0.4-2.8-0.3c-0.6-0.7-1.6-0.5-2.3,0.4l-6,7.4
                  			c-0.7,0.9-2.2,1.8-3.3,2.1l-5.9,1.5c-0.8,0.2-1.4-0.1-1.6-0.7l0,0L980.2,243.7z"/>
                  		<path className="state__path" d="M982.9,230.4c0.1-0.5,0.3-1.1,0.6-1.6l-0.1,0l-6.4-13.9l-1.3-7.9l-1.5-3.9l0.4-13.9l-5.4-19.6l-4.5-0.5
                  			l-3.4-8.2l1.1-6.8l-5.9-17.1l0-0.1l-19,6.1c-1.1,0.3-2.7,1.1-3.7,1.6l-2.9,1.6c-1,0.5-2.4,1.7-3.1,2.5l-2.3,2.8
                  			c-0.7,0.9-1.7,2.4-2.2,3.4l-2.3,5c-0.5,1-0.8,2.5-0.8,3.3c0,0.8-0.6,2.1-1.3,2.9l-3.8,4.1c-0.8,0.8-0.5,1.8,0.5,2.2l0.7,0.3
                  			c1.1,0.4,1.6,1.3,1.2,2.1c-0.4,0.8-0.4,2.2,0.1,3.2l0.9,2c0.5,1,0.1,2.5-0.7,3.2l-6.4,5.7c-0.8,0.7-2.4,1.5-3.5,1.7l-3.6,0.5
                  			c-1.1,0.2-2.5,0.9-3.1,1.7c-0.6,0.8-2,1.3-3.1,1.2l-4.8-0.4c-1.1-0.1-2.9,0.1-4,0.4l-4.9,1.3c-1.1,0.3-2.8,0.9-3.8,1.4l-7.3,3.5
                  			c-1,0.5-1.4,1.7-0.9,2.7l4.7,8.6c0.5,1,0.4,2.5-0.4,3.3l-0.8,0.8c-0.8,0.8-1.6,2.4-1.8,3.5l-0.1,0.4c-0.2,1.1-1.1,2.6-1.9,3.3
                  			l-5.7,5.1c-0.5,0.4-1.2,0.9-1.9,1.3v0l2.3,9.1c0,0,53.6-12.4,76.7-20l6.7,4l3.2,6.2l29.2,5.6l0,0c-0.1-0.2-0.1-0.5-0.1-0.8
                  			L982.9,230.4z"/>
                  	</g>
                  </g>
                  <g className="state new_jersey">
                  	<path className="state__path" d="M954.1,246.9l11.7,10.3l-10,11.2l3,8.7c0.2,0,0.4-0.1,0.8,0l7.7,1.1c1.1,0.3,1.9,1.5,1.9,2.6v2.5
                  		c0,1.1,0.9,1.9,2,1.7l0.5-0.1c1.1-0.2,2.2-1.2,2.4-2.3l0.9-4.3c0.2-1.1,1-2.7,1.8-3.5l0.4-0.5c0.8-0.8,1.6-2.4,1.8-3.5l1.2-5.9
                  		c0.2-1.1,0.3-2.9,0.2-4l-0.7-9.3c-0.1-1.1-1.1-2.1-2.2-2.2l-0.9-0.1c-1.1-0.1-1.7-1-1.3-2.1l0.3-0.7c0.4-1,1.6-2.2,2.7-2.5l1.8-0.5
                  		l2.4-9.6l-23.5-4.5l0,0.2L954.1,246.9z"/>
                  </g>
                  <g className="state new_hampshire">
                  	<path className="state__path" d="M988.1,138.2l-5.8,10.1l2.9,9.3l-2.8,9l3.9,12.9l-1.1,6.1l21.8-7.4l5.7-6.2l0.3-0.1c-0.1-0.7-0.1-1.4,0-1.9
                  		l0.4-2.7c0.1-0.9,0.5-2.2,0.8-3.3l-9.7-8.4l-13.8-36.2l0,0c-0.3,0.2-0.7,0.3-1.1,0.2L986,119c-1.1-0.2-2.3,0.5-2.7,1.6l-0.1,0.2
                  		c-0.4,1.1-0.1,2.3,0.6,2.7c0.7,0.4,1.1,1.6,0.8,2.6c-0.1,0.4-0.3,0.7-0.6,1.1l0,0L988.1,138.2z"/>
                  </g>
                  <g className="state nevada">
                  	<path className="state__path" d="M234.9,389.5l23.1-140l0,0c-31.6-6-67.3-13.6-100.5-22.1l-17.2,68.5l77,115.5l2.5-30.1L234.9,389.5z"/>
                  </g>
                  <g className="state nebraska">
                  	<path className="state__path" d="M589,322.4l-8.9-16l-0.9-14.8l-4.4-4.1l1.2-8.6l-4.6-5.8l-2.3-8.6l-6.9-0.5l-4.3-7.1l-10.2-3l-7.4,4.4l-9-7.5
                  		c-12.5,0.2-70.3-1.8-97.5-3.6l-3.4,48c11.8,0.8,23.7,1.6,35.6,2.2l-1.1,24.2C499.3,324.2,552.9,323.9,589,322.4z"/>
                  </g>

                	<g className="state montana">
                		<path className="state__path" d="M437.3,198.9l6.5-92.2c-62.6-4.2-122.2-12.7-183.2-25.8l-5.4,23.4l5.3,17.9v5.3l6.4,12l6.1,0.6l-7.4,21.5
                			l2.9,6.5l7.5-2.5l10.5,29.8l20.6-1l4.5-2.8l6.8,5.1l0,0l1.6-10.6C340.7,189.7,422.2,198.3,437.3,198.9z"/>
                	</g>

                  <g className="state missouri">
                  	<path className="state__path" d="M689.7,411l11.6-0.5l3.4-13.2l4.1-0.2l4.3-9.9l-9.5-8.6l-2.1-9.2l-15.3-9.7l4.1-15.7l-5.8-3.5l-4,3.5l-4.1-2.7
                  		l-0.1-6.9l-11.8-9.3l0.1-6.5l-3.7-2.1l0.6-6l0,0l-6.6-5.6l-0.2-0.1c-25.8,1.8-58.9,3.9-73.4,3.9l7.5,13.5l9.1,2.6l1.7,4.6l-4.7,4.1
                  		l5.4,7.6l3.8,1.7l2.1,62.5c20.4-0.5,82.7-5.2,89.2-6L689.7,411z"/>
                  </g>
                  <g className="state mississippi">
                  	<path className="state__path" d="M735.4,436.6l-4.8-5.7l-0.2,0c-14.3,1.9-27.2,3.4-36.5,4.2l-4.8,5.4l-1,9.1l-8,5.8l-3.6,16.2l3.6,8.1l-1.8,9.4
                  		l5.6,8.8l-3.1,9.6l-8.6,11.3l-0.2,10.7c8.3-0.6,32.8-2.8,38.2-3.4l-2.7,5.4l1.3,5.8l4.4,1.9l3.2,7.9h0c0.5-0.5,1-0.9,1.4-1.2
                  		l5.7-4.2c0.9-0.7,2.6-1.2,3.7-1.3l11.9-0.6l-4.5-33.8L735.4,436.6z"/>
                  </g>
                  <g className="state minnesota">
                  	<g>
                  		<g>
                  			<path className="state__path" d="M682.9,129.8l2.1,1.3c0.9,0.6,2.3,0.3,2.9-0.6l1.2-1.6c0.7-0.9,2-2.1,2.9-2.7l1.4-0.9c1-0.6,1.8-2,1.9-3.1
                  				l0.3-4.1c0.1-1.1-0.8-2-1.9-1.9l-1.6,0.1c-1.1,0.1-2.2,1.1-2.5,2.1v0c-0.3,1.1-1.3,2.5-2.2,3l-1,0.6c-1,0.6-2.2,1.8-2.9,2.8
                  				l-1.3,2C681.7,128,681.9,129.2,682.9,129.8z"/>
                  			<path className="state__path" d="M660.7,232.9l-3.2-8.6l-10.6-7.7l-2.1-4.9l-15.4-7l1.7-13.9l-4.4-3.6l2.1-6.6l6.6-5l-0.5-11.3l5.1-4.9
                  				l-1.7-0.6c-1.1-0.4-1.3-1.3-0.5-2.2l17.1-18.7c0.8-0.8,2.2-1.8,3.3-2.3l9-3.6c1-0.4,2.6-1.4,3.4-2.2l4.6-4.3
                  				c0.8-0.8,0.6-1.6-0.5-2l-7.3-2.1c-1.1-0.3-2.7-0.1-3.7,0.5l-1.8,1.1c-1,0.6-2.5,0.5-3.4-0.1l-3.3-2.4c-0.9-0.7-2.3-0.6-3.2,0.1
                  				l-4.9,4.2c-0.9,0.7-2.3,0.8-3.2,0.1l-9.4-6.7c-0.9-0.6-2.3-0.5-3,0.4l-1.5,1.8c-0.7,0.8-1.9,0.8-2.6-0.1l-3.7-4.9
                  				c-0.7-0.9-2.1-2-3.1-2.5l-5.3-2.5c-1-0.5-2.5-0.3-3.4,0.5l-5,4.3c-0.9,0.7-2.4,1-3.5,0.7l-21.8-6.8c-10.5,0.3-20.8,0.4-31,0.5
                  				l0,0l4.7,10.5l-2.3,11.1l5.1,10.6l1.7,24.5l4.2,8l1.9,21.1l0.9,40.3l-0.6,1.8C590.3,237.7,639.9,234.4,660.7,232.9L660.7,232.9z"
                  				/>
                  		</g>
                  		<g>
                  			<path className="state__path" d="M591.2,101.3c-1.5,0.9-7.7,3.1-7.7,3.1l-0.3-9.6c0,0,5.6,0.1,6.9,0.1C593.3,95,592.7,100.5,591.2,101.3z"/>
                  		</g>
                  	</g>
                  </g>
                  <g className="state michigan">
                  	<g>
                  		<path className="state__path" d="M750.4,169.3l-3.2,1.1c-1.1,0.4-1.8,1.6-1.7,2.7l0.2,2.4c0.1,1.1,1.1,1.9,2.2,1.7l2.3-0.4
                  			c1.1-0.2,2-1.3,2-2.4l0.1-3.7C752.3,169.5,751.5,168.9,750.4,169.3z"/>
                  		<path className="state__path" d="M673.8,167l20.9,4.3l8.8-3l4.1,6l3.5-0.9l2.5,3.6l-1.9,6.6l5.5,3.5l0,0c0.2-0.7,0.4-1.3,0.7-1.8l5.7-11.6
                  			c0.5-1,1.7-1.3,2.6-0.8c0.9,0.6,2.2,0.3,2.9-0.6l0.6-0.8c0.6-0.9,1.8-1.3,2.6-0.8c0.8,0.5,2,0.1,2.6-0.8l1-1.4c0.6-0.9,2-2,3-2.5
                  			l11.1-5.2c1-0.5,2.7-0.6,3.8-0.3l6.7,2c1.1,0.3,2.4-0.2,3-1.1l0.1-0.2c0.6-0.9,2-1.8,3.1-1.8l11.1-0.5c1.1-0.1,1.4-0.7,0.5-1.5
                  			l-2.5-2.4c-0.8-0.8-2-2.2-2.6-3.1l-1.9-3c-0.6-0.9-1.7-1.1-2.5-0.3l-0.3,0.3c-0.8,0.8-2.1,0.7-2.8-0.1l-0.2-0.2
                  			c-0.8-0.8-2.2-1.2-3.3-0.8l-3.4,1.2c-1.1,0.4-2.1-0.2-2.3-1.3l-0.3-1.6c-0.2-1.1-1.2-1.8-2.3-1.5l-15.8,3.9
                  			c-1.1,0.3-2.6,1.2-3.4,2l-2,2.2c-0.8,0.8-2.3,1.6-3.4,1.8l-6.9,1.1c-1.1,0.2-2.7-0.3-3.5-1.1l-4.2-4.1c-0.8-0.8-2.3-1.7-3.4-2.1
                  			l-1.8-0.6c-1.1-0.4-2.5,0-3.3,0.8l-2.6,2.7c-0.8,0.8-2.2,1-3.2,0.4l-1.1-0.7c-1-0.6-1.3-1.8-0.7-2.8l4-6.4c0.6-0.9,2-2,3-2.3
                  			l3.5-1.1c1.1-0.3,1.4-1.3,0.7-2.2l-1.9-2.4c-0.7-0.9-2.2-1.3-3.3-1l-5.8,1.5c-1.1,0.3-2.4,1.3-2.9,2.3l-1.6,3
                  			c-0.5,1-1.8,2.2-2.8,2.7l-1.4,0.7c-1,0.5-2.2,1.7-2.6,2.8l-0.2,0.4c-0.5,1-1.7,2.3-2.7,2.7l-7,3.3c-1,0.5-2.5,1.5-3.3,2.3
                  			l-4.9,4.6c-0.7,0.7-2,1-3,0.9l0,0L673.8,167z"/>
                  		<path className="state__path" d="M803.9,253.4c0-1.1,0.7-2.6,1.6-3.4l1.6-1.5c0.8-0.8,1.3-2.3,1-3.3l-0.5-2c-0.3-1.1,0.3-2.4,1.3-3l1.1-0.6
                  			c1-0.6,1.7-1.9,1.5-3l-0.5-4.7c-0.1-1.1,0.6-1.7,1.7-1.4l1.6,0.5c1.1,0.3,1.9-0.3,1.9-1.4v-8.9c0-1.1-0.7-2.6-1.5-3.4l-1.5-1.3
                  			c-0.8-0.7-1.7-2.2-2-3.3l-1.8-8.3c-0.2-1.1-1.2-2.5-2.1-3.2l-3.1-2.2c-0.9-0.7-2.4-0.7-3.4-0.1l-2.8,1.7c-1,0.6-2.2,1.8-2.8,2.8
                  			l-3.4,5.6c-0.6,1-1.9,1.4-3,1l-1.8-0.6c-1.1-0.4-2-1.6-2.1-2.7l-0.2-1.8c-0.1-1.1,0.7-2.3,1.8-2.6l1.1-0.3
                  			c1.1-0.3,2.3-1.4,2.8-2.4l1.5-3c0.5-1,0.9-2.7,0.9-3.9v-7.8c0-1.1-0.8-2.5-1.7-3.1l-0.1,0c-1-0.6-1.3-1.9-0.7-2.8l0.2-0.3
                  			c0.6-1,0.4-2.4-0.4-3.2l-1.5-1.4c-0.8-0.8-2.3-1.6-3.4-1.9l-11.2-2.4c-1.1-0.2-2.3-0.9-2.6-1.5c-0.4-0.6-1.5-0.9-2.6-0.7l-3.2,0.6
                  			c-1.1,0.2-2.7,1-3.5,1.7l-0.9,0.8c-0.8,0.7-1.3,2.3-1.1,3.4l0,0.2c0.2,1.1-0.2,2.7-0.9,3.6l-0.6,0.7c-0.7,0.9-0.8,1.9-0.2,2.3
                  			c0.6,0.4,1,1.7,0.8,2.8l-0.2,1.9c-0.1,1.1-1,2.4-2,3l-1.6,0.8c-1,0.5-1.9,0-2-1.1l-0.2-1.5c-0.1-1.1-0.9-2.6-1.7-3.2
                  			c-0.8-0.6-2-0.4-2.6,0.6l-4.4,7.2c-0.6,1-1,2.6-0.9,3.8l0.2,3c0.1,1.1-0.3,2.8-0.8,3.8l-1.8,3.7c-0.5,1-0.8,2.7-0.6,3.8l0.8,6
                  			c0.1,1.1,0,2.9-0.3,4l-0.2,0.6c-0.3,1.1-0.1,2.7,0.5,3.7l6.1,9.8c0.6,0.9,1.2,2.6,1.3,3.7l1.1,9.2c0.1,1.1-0.1,2.9-0.6,3.9
                  			l-7,15.8l0,0.1c18.5-2.5,48.8-7.2,62.1-10l0,0c-0.4-0.5-0.6-1.1-0.6-1.7L803.9,253.4z"/>
                  	</g>
                  </g>
                  <g className="state massachusetts">
                  	<g>
                  		<path className="state__path" d="M1036.6,205.9l-3.5,0c-0.9,0-1.9,0.7-2.2,1.5l-0.2,0.6c-0.3,0.8,0.1,1.7,1,1.9l2.1,0.6c0.9,0.2,1.9-0.2,2.4-1
                  			l1.3-2.2C1037.9,206.5,1037.5,205.9,1036.6,205.9z"/>
                  		<path className="state__path" d="M1023.4,204.2l0.2,0.3c0.5,1,1.5,1.2,2.3,0.5l6.7-6c0.8-0.7,2.2-0.9,3.1-0.2c0.9,0.6,1.9,0.3,2.3-0.8l0.6-1.5
                  			c0.4-1,0.3-2.7-0.2-3.7l-1.7-3.1c-0.5-1-1.8-1.7-3-1.5l-2.3,0.4c-1.1,0.2-1.6,1.1-1.1,2.1l0.3,0.6c0.5,1,0.1,2.2-0.9,2.8l-0.9,0.4
                  			c-1,0.5-2.6,0.4-3.5-0.2l-1.6-1.1c-0.9-0.6-2.2-1.9-2.8-2.9l-1.3-2c-0.6-0.9-1.5-0.9-2,0.1l-0.1,0.2c-0.5,1-1.5,1.2-2.3,0.4
                  			l-1.2-1.2c-0.8-0.8-0.9-2.2-0.3-3.2l4.1-6.1c0.6-0.9,0.2-1.9-0.9-2.1l-0.8-0.2c-1.1-0.2-2.4-1.2-2.8-2.2l-0.1-0.2
                  			c-0.2-0.5-0.4-1.3-0.5-2l-0.3,0.1l-5.7,6.2l-32.4,11l-0.4,13.9l1.5,3.9l35.1-11.8l6.9,5.9l1.9,5.2l1.8-2.3
                  			C1022,203.1,1022.9,203.2,1023.4,204.2z"/>
                  	</g>
                  </g>
                  <g className="state maryland">
                  	<path className="state__path" d="M886.6,302.3l11.2-11.4l6.4,1.9l6.8-5.5l13.5,5.1v4.3l7.3,0.6l1.3,6.7l-3.9,6.6l2.7,2.8l5.4-2.3l11.4,3.2
                  		c0.1-0.3,0.3-0.6,0.7-0.8l0.7-0.4c1-0.6,1.4-1.9,0.9-2.9l-2.1-4.8c-0.4-1,0-1.4,0.9-0.7l10.9,7.2c0.4,0.3,0.7,0.7,1,1.1l0,0l12-4
                  		l0,0l0.9-8.5c0.1-0.8-0.1-1.8-0.4-2.7l-14,3.6l-11-31c0,0-37.1,10.4-65.2,17.1l0,0L886.6,302.3z"/>
                  </g>
                  <g className="state maine">
                  	<path className="state__path" d="M1004.4,155.5l9.7,8.4c0.1-0.2,0.2-0.4,0.2-0.6l0.8-1.9c0.4-1,0.5-2.5,0.2-3.3c-0.3-0.8,0-2,0.7-2.6
                  		c0.7-0.7,0.8-1.9,0.1-2.8c-0.6-0.9-0.7-2.4-0.2-3.3l0.2-0.3c0.5-1,1.9-1.5,3-1.2l1.4,0.4c1.1,0.3,1.8-0.4,1.5-1.5l-0.4-2
                  		c-0.2-1.1,0.2-2.6,1.1-3.3l1.2-1.1c0.8-0.7,2.4-1.1,3.5-0.9c1.1,0.3,1.9-0.4,1.8-1.5l-0.5-8.1c-0.1-1.1,0.5-2.7,1.3-3.5l0.1-0.2
                  		c0.8-0.8,2-0.7,2.6,0.2l2.7,3.7c0.7,0.9,1.2,0.7,1.3-0.4l0.1-1.5c0-1.1,1-1.9,2.1-1.7l0.5,0.1c1.1,0.2,1.6-0.5,1.1-1.5l-0.8-1.7
                  		c-0.5-1-0.2-2.4,0.7-3c0.9-0.7,2.3-0.7,3.2,0l0,0c0.9,0.7,2.3,0.6,3.2-0.1l0.1-0.1c0.9-0.7,1.6-2.1,1.7-3.2c0.1-1,0.8-2.5,1.7-3.2
                  		c0.8-0.7,2.5-1.3,3.6-1.3l0.7,0c1.1,0,2.3-0.9,2.7-2l1.2-3.7c0.3-1.1-0.3-2.1-1.4-2.4l-0.1,0c-1.1-0.3-2.5,0.3-3.2,1.2l-0.2,0.2
                  		c-0.7,0.9-2,1.3-3.1,0.8l-0.6-0.2c-1-0.4-1.7-1.7-1.5-2.8l0.2-1c0.2-1.1-0.6-2-1.7-2h-0.8c-1.1,0-2.4-0.8-2.9-1.9l-2.6-5.8
                  		c-0.5-1-1.7-1.9-2.9-2l-1.7-0.1c-1.1-0.1-2.4-1-2.7-2l-10.8-28.8c-0.4-1-1.5-2.3-2.6-2.7l-5.6-2.6c-1-0.5-2.5-0.2-3.2,0.7l-4.1,4.5
                  		c-0.8,0.8-2.1,1.2-3,0.9c-0.9-0.3-1.7-1.5-1.9-2.6c-0.1-1.1-1.2-2.1-2.3-2.3l-0.7-0.1c-1.1-0.2-2.2,0.6-2.4,1.7l-4.2,19.9
                  		c-0.2,1.1-0.1,2.9,0.3,3.9l1.1,3.1c0.4,1.1,0.4,2.8,0.1,3.9l-0.8,3.1c-0.3,1.1-0.2,2.8,0.3,3.8l1.4,3.2c0.5,1,0.5,2.7,0.1,3.8
                  		l-1.8,5.2c-0.4,1.1-0.2,2.7,0.4,3.6l0.6,0.9c0.6,0.9,0.2,2.1-0.8,2.5l-0.1,0c-1,0.4-1.9,1.7-1.9,2.8v0.6c0,0.7-0.4,1.2-0.9,1.5l0,0
                  		L1004.4,155.5z"/>
                  </g>
                  <g className="state louisiana">
                  	<path className="state__path" d="M730,566.3l-7.2-4.1c-1-0.6-1.1-1.6-0.3-2.4l2.6-2.4c0.8-0.8,1.3-2.3,1.2-3.4l-0.5-3.5c-0.2-1.1-1-1.5-1.9-0.8
                  		l-5.8,4.4c-0.9,0.7-2.3,0.7-3.2,0l-1.1-0.9c-0.9-0.7-1-2-0.3-2.8l1.5-1.8c0.4-0.5,0.9-1,1.5-1.6h0l-3.2-7.9l-4.4-1.9l-1.3-5.8
                  		l2.7-5.4c-5.4,0.6-29.9,2.8-38.2,3.4l0.2-10.7l8.6-11.3l3.1-9.6l-5.6-8.8l1.2-6.1l-59.1,3.7l0.6,21.8l5.3,8.9v6.3l7,7.9l-4.7,15.5
                  		l1.9,4.9l-2.9,12.6l0,0l3.9-1.8c1-0.5,2.8-0.9,3.9-0.9l7.2,0c1.1,0,2.9,0.3,3.9,0.7l11.1,3.9c1.1,0.4,2.8,0.6,3.9,0.4l6.1-0.8
                  		c1.1-0.2,1.4-1,0.7-1.8l-2.3-2.6c-0.7-0.8-0.5-1.9,0.6-2.3l3-1.1c1-0.4,2.6-0.1,3.4,0.6l5.4,4.6c0.9,0.7,1.9,2.2,2.2,3.2l0.5,1.5
                  		c0.4,1,1.6,2.1,2.7,2.4l4.2,1c1.1,0.3,2.8,0,3.8-0.5l4.2-2.1c1-0.5,2.5-0.4,3.4,0.4l3.6,2.9c0.9,0.7,2.3,0.8,3.3,0.2l6.5-4.3
                  		c0.9-0.6,2.5-0.8,3.6-0.4l1.1,0.4c1,0.4,1.9,1.7,1.9,2.8l0,3.9c0,1.1,0.8,1.7,1.9,1.4l6.2-2.1c1.1-0.4,2.3-1.5,2.7-2.5l1.1-2.6
                  		C731.4,568.1,731,566.8,730,566.3z"/>
                  </g>
                  <g className="state kentucky">
                  	<path className="state__path" d="M832.9,332.4l-7.9-7L819,331l-16.7-2.1l-6.4-7.4l-7.7,1.9l1.6,6.8l-11.3,3.6l-9,20.2l-6.7-4.1l-4.1,8.1
                  		l-6.3-2.4l-3.4,5.1l-7.9-2.5l-10.8,5.2l-0.3,2l2.9,4.9l-8.6,2.9l2.7,5.5l-2.7,3.8l-8.9-4.2l-5,6.3l3,2.7l-4.3,9.9l25.5-1.7l0.4-4.4
                  		c29.9-2.7,57.7-6.6,83.6-11.1l0,0l28.7-28.8l-10.9-4.3L832.9,332.4z"/>
                  </g>
                  <g className="state kansas">
                  	<path className="state__path" d="M605.9,392.8l-1.6-49.9l-3.8-1.7l-5.4-7.6l4.7-4.1l-1.7-4.6l-9.1-2.6c-36,1.5-89.7,1.8-124-0.8l-3.2,71
                  		C514.6,395,564,394.9,605.9,392.8z"/>
                  </g>
                  <g className="state iowa">
                  	<path className="state__path" d="M655.1,305l6.6,5.6l0,0l0.6-6.1l5.4-2.1l3-9l-5.2-3.8v-6.1l12.1-3.8l4.4-13.9l-11.6-12l-6.5-2.4l-3.9-8.8
                  		l2.8-4.4l-2-5.3l0,0c-20.8,1.5-70.4,4.8-94.5,4.8l-2.2,6.9l5.2,5.4l-2.3,6.8l4.4,16.2l4.6,5.8l-1.2,8.6l4.4,4.1l0.9,14.8l1.4,2.6
                  		c14.5,0,47.5-2.2,73.4-3.9L655.1,305z"/>
                  </g>
                  <g className="state indiana">
                  	<path className="state__path" d="M778.5,260.5c-12.4,2-26,4-36.1,5.4l0-0.1l-1.2,2.7c-0.5,1-1.7,1.9-2.9,1.9H732c-0.7,0-1.5-0.3-2.2-0.8
                  		L736,321l3.3,2.4l-3.6,7.3l3.6,8.4l-7.6,13.4l-1.7,10.8l10.8-5.2l7.9,2.5l3.4-5.1l6.3,2.4l4.1-8.1l6.7,4.1l9-20.2l11.3-3.6
                  		l-1.6-6.8L778.5,260.5L778.5,260.5z"/>
                  </g>
                  <g className="state illinois">
                  	<path className="state__path" d="M715.2,378.2l8.9,4.2l2.7-3.8l-2.7-5.5l8.6-2.9l-2.9-4.9l2-12.8l7.6-13.4l-3.6-8.4l3.6-7.3L736,321l-6.1-51.6
                  		c-0.4-0.3-0.7-0.6-0.9-0.9l-5.9-10c-0.6-1-1-2.7-0.9-3.8l0.2-3.4l-49.2,5.3l8.9,9.2l-4.4,13.9l-12.1,3.8v6.1l5.2,3.8l-3,9l-5.4,2.1
                  		l-1.3,12.1l3.7,2.1l-0.1,6.5l11.8,9.3l0.1,6.9l4.1,2.7l4-3.5l5.8,3.5l-4.1,15.7l15.3,9.7l2.1,9.2l6.5,5.8l0,0L715.2,378.2z"/>
                  </g>
                  <g className="state idaho">
                  	<path className="state__path" d="M318.4,196.7L318.4,196.7l-6.8-5.1l-4.5,2.8l-20.6,1l-10.5-29.8l-7.5,2.5l-2.9-6.5l7.4-21.5l-6.1-0.6l-6.4-12
                  		v-5.3l-5.3-17.9l5.4-23.3l0,0c-5.4-1.2-10.9-2.4-16.4-3.6l0,0l-14.7,61.2l0.6,12.9l4,1.3l0.9,4.8l-10.1,14.1l-5.1,2.4l-3.8,9l4,1.3
                  		l1,4.5l-5,6.7l-9.4,43.5c38,8.3,75.2,15.2,102.7,19.4L318.4,196.7z"/>
                  </g>
                  <g className="state hawaii">
                  	<g>
                  		<g>
                  			<path className="state__path" d="M537,758.3c-1.1-0.2-1.8,0.5-1.6,1.6l1.1,6.3c0.2,1.1,0.9,2.7,1.6,3.6l9.2,11.4c0.7,0.9,1.8,0.9,2.5,0l1.5-2
                  				c0.7-0.9,2.2-1.7,3.3-1.9l3.7-0.5c1.1-0.2,2.3-1.2,2.6-2.2l0.6-2.2c0.3-1.1-0.2-2.5-1-3.2l-9.6-7.4c-0.9-0.7-2.5-1.4-3.6-1.6
                  				L537,758.3z"/>
                  		</g>
                  		<g>
                  			<path className="state__path" d="M518,743.5c0.9-0.6,2.5-0.7,3.5-0.2l10,5.1c1,0.5,1,1.3,0,1.8l-7.8,4c-1,0.5-2.5,0.3-3.3-0.5l-5.9-5.4
                  				c-0.8-0.8-0.7-1.9,0.2-2.5L518,743.5z"/>
                  		</g>
                  		<g>
                  			<path className="state__path" d="M505.7,734.2c-1,0.5-1.5,1.7-1,2.7l1.5,3.3c0.5,1,1.7,1.5,2.7,1l7-3.2c1-0.5,1.1-1.4,0.3-2.1l-3.6-2.9
                  				c-0.9-0.7-2.4-0.9-3.4-0.4L505.7,734.2z"/>
                  		</g>
                  		<g>
                  			<path className="state__path" d="M484.7,729.3c0.8-0.7,2.1-0.7,2.9,0.1l7.5,7.7c0.8,0.8,0.5,1.7-0.5,2l-4.6,1.2c-1.1,0.3-2.6-0.1-3.4-0.9
                  				l-4.8-4.7c-0.8-0.8-0.8-2,0.1-2.8L484.7,729.3z"/>
                  		</g>
                  		<g>
                  			<path className="state__path" d="M458.2,719.6c0.7-0.9,2-1,2.9-0.3l2.9,2.3c0.9,0.7,1.6,2.2,1.5,3.3l-0.1,4.3c0,1.1-0.9,1.6-1.9,1.1l-8.1-4.1
                  				c-1-0.5-1.2-1.6-0.5-2.5L458.2,719.6z"/>
                  		</g>
                  	</g>
                  </g>
                  <g className="state georgia">
                  	<path className="state__path" d="M800.3,471.8l7.1,13l-3.6,4.2l2.1,4.7l-1.8,3.8l3.3,5.9l-1.1,5l6.1,12l52.7-6l2.4,4.2l5.8-1l-1.5-11.5
                  		l12.8,0.9l-0.8-2.8c-0.3-1.1-0.3-2.8,0.1-3.8l2-5.2c0.4-1,0.7-2.8,0.7-3.9v-6.9c0-1.1,0.5-2.8,1.1-3.7l3.4-5.2l0,0l-6.7-0.1
                  		l-1.9-9.6l-4.8-2.4l-3-8.8l-7.2-2.1l-3.6-6.6l-14.6-8.1l-7.5-10.9l-10.5-4.1l3.6-7.7c-10.1,1.8-28.4,4.8-48.9,7.9l-0.4,0.6
                  		L800.3,471.8z"/>
                  </g>
                  <g className="state florida">
                  	<g>
                  		<path className="state__path" d="M925.3,628.1l-14.6,17.2c-0.8,0.9-0.8,1.3-0.1,0.9l7.9-4.7c0.7-0.4,1.9-1.8,2.5-3.1l4.7-9.7
                  			C926.3,627.5,926.1,627.2,925.3,628.1z"/>
                  		<path className="state__path" d="M906.5,649.4l-7,1.2c-1,0-2.2,0.6-2.6,1.2l-10.4,9.2c-0.5,0.6-0.2,0.7,0.6,0.3l19.7-11
                  			C907.6,649.7,907.5,649.3,906.5,649.4z"/>
                  		<path className="state__path" d="M767,533.6l0.5-1c0.5-1,1.4-1.1,2-0.1l0.4,0.6c0.6,0.9,2,1.5,3.1,1.2l5.7-1.5c1.1-0.3,2.9-0.3,3.9,0l12.2,3.4
                  			c1.1,0.3,2.8,1,3.8,1.5l4.8,2.4c1,0.5,1.8,1.8,1.8,3v1.4c0,1.1,0.9,1.9,2,1.7l6.3-0.9c1.1-0.2,2.7-0.8,3.6-1.5l7.6-5.7
                  			c0.9-0.7,1.6-2.1,1.5-3.2l-0.1-1.9c-0.1-1.1,0.8-2,1.9-1.9l2.4,0.1c1.1,0.1,2.8,0.6,3.8,1.1l10.1,5.9c1,0.6,2,1.9,2.3,2.9
                  			c0.3,1,1.3,2.3,2.3,2.8l12.5,6.9c1,0.5,1.7,1.9,1.7,3l-0.6,14.8c0,1.1,0.1,2.9,0.3,4l1.7,8.4c0.2,1.1,1.1,1.3,1.8,0.6l1.3-1.3
                  			c0.8-0.8,1.9-0.6,2.4,0.4l0.9,1.7c0.5,1,0.4,2.6-0.1,3.6l-0.5,0.9c-0.6,1-0.5,2.5,0.2,3.4l6.3,8.5c0.7,0.9,1.8,0.9,2.6,0.1
                  			l2.2-2.5c0.7-0.8,1.4-0.6,1.4,0.5l0.3,6.8c0,1.1,0.8,2.5,1.8,3.1l1.8,1.2c0.9,0.6,2,2,2.4,3l2.3,6.1c0.4,1,1.6,2.2,2.6,2.6
                  			l7.3,2.5c1.1,0.4,2.3,1.5,2.7,2.5l2.3,5.7c0.4,1,1.7,1.7,2.8,1.5l7.5-1.6c1.1-0.2,2.4-1.2,2.9-2.2l4.7-9c0.5-1,1.5-1.9,2.3-1.9
                  			c0.7-0.1,1.3-1,1.3-2.2l-0.6-18c0-1.1-0.4-2.9-0.8-3.9l-3.5-9.9c-0.4-1.1-1.2-2.7-1.8-3.6l-8.3-13c-0.6-0.9-1.4-2.6-1.7-3.7
                  			l-2.1-7.3c-0.3-1.1-1.3-1.4-2.1-0.6l-1,0.8c-0.9,0.7-1.9,0.4-2.3-0.6l-1.4-3.9c-0.4-1,0.2-2.1,1.3-2.3l0.7-0.2
                  			c1.1-0.2,1.4-1.2,0.8-2.1L892.1,530c-0.7-0.9-1.5-2.5-1.8-3.6l-5.9-19.4l-12.8-0.9l1.5,11.5l-5.8,1l-2.4-4.2l-52.7,6l-3.7-7.2
                  			c-7.2,1.1-41.3,5.7-53.8,7.4l-1.5,4.2l6.1,4.2l-1.1,9l5.9-1.9C765.2,535.8,766.5,534.7,767,533.6z"/>
                  	</g>
                  </g>
                  <g className="state delaware">
                  	<path className="state__path" d="M973.6,296.7l-2.6-4.6c-0.5-1-1.9-2-3-2.2l-0.7-0.2c-1.1-0.2-2.5-1.2-3-2.2l-5.4-10.5l-3-8.7l-5.7-2.4
                  		l-1.1,4.3l11,31l14-3.6C973.9,297.4,973.8,297,973.6,296.7z"/>
                  </g>
                  <g className="state connecticut">
                  	<path className="state__path" d="M975.7,206.9l1.3,7.9l6.4,13.9l0.1,0c0.4-0.6,0.8-1.2,1.3-1.5l2.3-1.6c0.9-0.6,2-1.9,2.4-2.8
                  		c0.4-0.9,1.6-2,2.6-2.4l16.5-7.1l-5.4-15.7L975.7,206.9z"/>
                  </g>
                  <g className="state colorado">
                  	<path className="state__path" d="M466.1,297.4c-11.9-0.6-23.9-1.4-35.6-2.2c-32.4-2.3-63.5-5.4-89.8-8.6l0,0l-11,94.8
                  		c44.8,5.6,89.5,9.2,132.2,11.2L466.1,297.4z"/>
                  </g>
                  <g className="state california">
                  	<g>
                  		<path className="state__path" d="M138.5,430.4c-0.7-0.5-1.6-0.4-2.1,0.4l-0.6,1.1c-0.4,0.8-0.3,1.9,0.4,2.5l3,2.9c0.6,0.6,1.8,0.8,2.6,0.4
                  			l2.1-1.1c0.8-0.4,0.9-1.2,0.2-1.8L138.5,430.4z"/>
                  		<path className="state__path" d="M119.9,411l-3.5-0.3c-0.7-0.1-1.2,0.5-1.1,1.2l0.2,1.3c0.1,0.7,0.8,1.3,1.5,1.4l3.5,0.4
                  			c0.7,0.1,1.2-0.4,1.1-1.1l-0.3-1.6C121.3,411.6,120.7,411,119.9,411z"/>
                  		<path className="state__path" d="M106.1,405.9l-1.5-0.3c-0.7-0.2-1.3,0.3-1.3,1l-0.1,1.3c0,0.7,0.5,1.5,1.2,1.7l1.2,0.4
                  			c0.7,0.2,1.3-0.2,1.4-0.9l0.2-1.5C107.3,406.7,106.8,406,106.1,405.9z"/>
                  		<path className="state__path" d="M209.3,448.7l5.2-16l9.1-2.9l-6.3-18.5l-77-115.5l17.2-68.5C132,220.9,107.9,214,88.1,207
                  			c0.1,0.6-0.1,1.2-0.4,1.7l-1.9,2.8c-0.6,0.9-1.2,2.6-1.3,3.7L84,225.5c-0.1,1.1-0.6,2.8-1.3,3.7l-5.6,8.1
                  			c-0.6,0.9-1.2,2.6-1.3,3.7l-0.4,5.4c-0.1,1.1,0.1,2.9,0.5,4l2.9,8.9c0.3,1.1,0.4,2.8,0.2,3.9l-2.1,8.9c-0.3,1.1-0.3,2.9,0,4
                  			l2.4,11.1c0.2,1.1,0.9,2.8,1.4,3.8l3.1,5.5c0.5,1,0.7,2.6,0.3,3.7l-1.1,3c-0.4,1,0.1,1.8,1,1.6c0.9-0.2,2.2,0.4,2.9,1.4l0.8,1.1
                  			c0.6,0.9,1.7,0.9,2.3,0l1.8-2.6c0.6-0.9,1.9-1.2,2.8-0.5l0.9,0.6c0.9,0.6,1.4,2,1,3.1l-0.4,1.3c-0.3,1.1-0.5,2.8-0.3,3.9l0.5,2.8
                  			c0.2,1.1-0.5,1.7-1.5,1.3l-3.3-1.3c-1-0.4-1.9,0.2-1.9,1.3l-0.1,13.6c0,1.1,0.9,2.2,2,2.4l1.2,0.2c1.1,0.2,2.1,1.3,2.3,2.4
                  			l0.7,3.9c0.2,1.1-0.6,1.9-1.7,1.7L93,341c-1.1-0.2-1.9,0.6-1.8,1.7l0.7,5.9c0.1,1.1,0.8,2.8,1.4,3.7l3,4.2
                  			c0.6,0.9,1.3,2.6,1.5,3.7l0.8,4.3c0.2,1.1,0.9,2.7,1.5,3.7l3.6,5c0.6,0.9,1,2.6,0.9,3.7l-0.1,0.6c-0.2,1.1,0.4,2.5,1.4,3.2
                  			l0.2,0.1c0.9,0.6,1.5,2,1.2,3.1l-2.2,9.3c-0.3,1.1,0.2,2.6,1.1,3.3l3.4,2.8c0.9,0.7,2.4,1.6,3.5,1.9l10.1,3.1
                  			c1.1,0.3,2.5,1.3,3.3,2.1l7.7,8.9c0.7,0.8,2.2,1.6,3.3,1.8l0.6,0.1c1.1,0.1,2.5,1,3,2l0.5,0.8c0.6,1,0.9,2.5,0.6,3.3
                  			c-0.2,0.9,0.5,1.8,1.6,2l1.1,0.2c1.1,0.2,2.6,1.1,3.3,2l6.1,7.4c0.7,0.9,1.5,2.5,1.7,3.6l3.2,18.3l49.5,5.5l5.9-8.3L209.3,448.7z"
                  			/>
                  	</g>
                  </g>
                  <g className="state arizona">
                  	<path className="state__path" d="M234.9,389.5l-15-8.3l-2.5,30.1l6.3,18.5l-9.1,2.9l-5.2,16l5.1,5.1l-5.9,8.3l-3.3,5.5l69.8,41.8l39.1,5.1
                  		l15.4-133.1c-30.6-3.8-61.2-8.6-91-14.4L234.9,389.5z"/>
                  </g>
                  <g className="state arkansas">
                  	<path className="state__path" d="M680.1,479.7l-3.6-8.1l3.6-16.2l8-5.8l1-9.1l9.2-10.3l-3.4-4.2l7.1-5.3l-2.2-4.3l1.5-5.8l-11.6,0.5l5.8-11.5
                  		c-6.6,0.8-68.9,5.5-89.2,6l4.9,69l8.8-1.8l0.4,14l59.1-3.7L680.1,479.7z"/>
                  </g>
                  <g className="state alaska">
                  	<g>
                  		<path className="state__path" d="M166.4,657.8l1.4,1.8c0.6,0.8,1.9,1,2.8,0.4l2.3-1.5c0.9-0.6,1-1.5,0.3-2c-0.8-0.5-1.8-1.6-2.3-2.5l-0.9-1.5
                  			c-0.5-0.9-1.8-1.8-2.9-1.9l-0.3,0c-1.1-0.2-2.4-1-2.9-1.9l-0.6-1c-0.5-0.9-1.2-0.8-1.6,0.1l-1.7,4.1c-0.4,0.9,0.1,2.1,1.1,2.6
                  			l2.4,1.1C164.4,655.9,165.8,656.9,166.4,657.8z"/>
                  		<path className="state__path" d="M167.3,700.7l1.7-0.3c1.1-0.2,2-1.1,2.1-2.1l0.1-2.7c0-1-0.7-2.2-1.7-2.7l-1.5-0.7c-1-0.5-2.1-0.3-2.4,0.4
                  			c-0.3,0.7-1.5,1.2-2.6,1.3l-0.2,0c-1.1,0.1-1.6,0.8-1,1.7l2.5,3.8C164.9,700.3,166.2,700.9,167.3,700.7z"/>
                  		<path className="state__path" d="M379.2,777l-1.6-0.7c-1-0.4-1.9-1.6-2-2.6l0-0.6c-0.1-1-1-2.2-1.9-2.7l-1.1-0.5c-1-0.5-1.9-1.7-1.9-2.7
                  			l-0.3-6c-0.1-1-0.8-1.3-1.6-0.6l-0.7,0.6c-0.8,0.7-2.4,1.1-3.5,1l-1.8-0.2c-1.1-0.1-2,0.6-2,1.6v3.1c0,1,0.6,2.4,1.4,3.2l7.8,7.8
                  			c0.7,0.7,2.1,1.9,3,2.5l6.1,4.1c0.9,0.6,1.7,0.3,1.8-0.7l0.4-3.9C381,778.6,380.2,777.4,379.2,777z"/>
                  		<path className="state__path" d="M363.6,756.7l-0.6-5.2c-0.1-1-0.9-2.4-1.7-3.1l-7.4-6.5c-0.8-0.7-1.7-0.5-1.9,0.5l-1.1,4.2
                  			c-0.2,1,0.4,2,1.5,2.3l1.5,0.4c1.1,0.3,2,1.4,2.1,2.4l0.1,1.8c0.1,1,1,2,2.1,2.2l5.3,1.1V756.7z"/>
                  		<path className="state__path" d="M346.8,735.1l-1.1-0.7c-0.9-0.6-2.3-0.5-3.2,0.1l-1.4,1.1c-0.8,0.7-1.1,1.9-0.6,2.8l3.4,5.7
                  			c0.5,0.9,1.2,0.8,1.6-0.1l2.3-6.1C348.2,737,347.7,735.7,346.8,735.1z"/>
                  		<path className="state__path" d="M342.9,727.2l-0.7-1.9c-0.4-1-1.4-1.3-2.4-0.8l-2.2,1.2c-0.9,0.5-1.4,1.7-0.9,2.7l0.8,1.7
                  			c0.4,0.9,1.6,1.2,2.5,0.7l1.8-1C342.8,729.4,343.3,728.2,342.9,727.2z"/>
                  		<path className="state__path" d="M238,724l-0.1,0.3l-2.9,4.1c-0.6,0.8-1,2.2-1,3c0.1,0.8-0.8,1.6-1.9,1.7l-0.4,0c-1.1,0.1-2.6,0.8-3.4,1.5
                  			l-2.2,2.2c-0.8,0.7-1.1,2.1-0.7,3.1l1.8,4.9c0.3,1,1,1,1.6,0.1l1.3-2.4c0.5-0.9,1.8-1.5,2.9-1.4l0.4,0c1.1,0.1,2-0.6,1.9-1.6
                  			l0-0.9c0-1,0.8-1.8,2-1.8h1.5c1.1,0,1.8-0.8,1.6-1.8l-0.4-1.6c-0.3-1,0.2-2.2,1.1-2.7c0.9-0.5,1-1.6,0.4-2.4L238,724z"/>
                  		<path className="state__path" d="M195.8,761.3l-2-1c-1-0.5-2.3-0.2-3,0.6l-0.8,1.1c-0.7,0.8-0.4,1.9,0.6,2.4l1.6,0.9c1,0.5,2.3,0.3,3-0.5
                  			l1.2-1.3C197,762.8,196.8,761.7,195.8,761.3z"/>
                  		<path className="state__path" d="M357,735c0.9,0.6,1.7,1.9,1.7,2.9v0.5c0,1,0.9,1.8,2,1.8h0.5c1.1,0,2.4,0.8,2.8,1.7l2.7,6.1
                  			c0.4,0.9,1.3,1,2,0.2l0.9-1.1c0.7-0.8,1.5-0.7,1.9,0.3l1.4,3.9c0.4,1,1.1,1,1.7,0.1l2.6-4.1c0.6-0.9,0.7-2.4,0.3-3.3l-2.3-5.7
                  			c-0.4-0.9-1.5-2.1-2.5-2.5l-9.7-4.5c-1-0.5-2.2-1.6-2.7-2.5l-4.2-8.2c-0.5-0.9-1.6-2.1-2.5-2.8l-13.9-9.3c-0.9-0.6-2-1.8-2.5-2.7
                  			l-1.8-3.4c-0.5-0.9-1.7-1.2-2.6-0.6l-2,1.2c-0.9,0.6-2.1,1.7-2.7,2.6l-3.6,6.1l-11.8-13.4l-9.9,1.4l-1.2-101.1l-7-3.1
                  			c-1-0.4-2.7-0.6-3.8-0.4l-5.8,1.2c-1.1,0.2-2.8,0-3.8-0.4l-10.9-4.9c-1-0.4-2.7-0.8-3.9-0.9L263,590c-1.1,0-2.2-0.9-2.4-1.9
                  			l-0.2-1c-0.2-1-1.3-1.7-2.4-1.6l-3.5,0.3c-1.1,0.1-2.5-0.5-3.1-1.4l-1.5-2.2c-0.6-0.9-1.8-1.1-2.7-0.4l-4.6,3.2
                  			c-0.9,0.6-2.5,1.3-3.6,1.5l-11,2.2c-1.1,0.2-2.6,1-3.3,1.8l-9.2,10.2c-0.7,0.8-2.2,1.3-3.3,1.2l-3.8-0.5c-1.1-0.1-2.6,0.4-3.3,1.2
                  			l-2.3,2.4c-0.7,0.8-0.9,2.1-0.5,3c0.5,0.9,1.4,2,2.2,2.5c0.7,0.5,1.6,1.7,2,2.6l0.8,2.2c0.4,1,0.6,2.6,0.6,3.6l0,1.3
                  			c0,1,0.8,2.1,1.9,2.5l3.9,1.4c1,0.4,2.2,1.5,2.6,2.4l0.9,2c0.4,0.9-0.1,1.4-1.2,1.1l-2.9-0.9c-1.1-0.3-1.9,0.7-1.4,1.6l2.4,2.8
                  			c0.5,0.9,0.1,1.8-1,2l-1.7,0.3c-1.1,0.2-2.9,0.1-3.9-0.1l-2.2-0.6c-1.1-0.3-1.7-1.3-1.4-2.3l0.4-1.2c0.3-1,0.1-2.4-0.4-3.1
                  			c-0.6-0.7-1.8-0.9-2.8-0.4l-3.5,1.9c-1,0.5-2.5,0.7-3.5,0.4c-1-0.3-2.6-0.1-3.5,0.4l-4.3,2.3c-1,0.5-1.1,1.5-0.3,2.2l3.3,2.9
                  			c0.8,0.7,0.6,1.6-0.4,2c-1,0.4-1.8,1.6-1.9,2.6l0,2.1c0,1,0.7,2.4,1.5,3l2.4,1.8c0.9,0.7,2.5,1.2,3.6,1.2h3c1.1,0,2.3,0.8,2.6,1.7
                  			c0.3,0.9,1.3,1.2,2.1,0.6l3.8-2.6c0.9-0.6,2.4-0.6,3.3-0.1l1,0.6c0.9,0.6,1,1.6,0.2,2.2l-1.1,0.9c-0.8,0.7-1.6,2-1.7,3.1l-0.3,4
                  			c-0.1,1-1,2.1-2,2.5l-1.1,0.4c-1,0.4-2.4,0.3-3-0.2c-0.6-0.5-1.7-0.3-2.5,0.4l-2.5,2.2c-0.8,0.7-2.1,0.7-2.8-0.1l-1.3-1.2
                  			c-0.8-0.7-1.8-0.6-2.3,0.3l-2.2,3.7c-0.5,0.9-1.7,2.1-2.6,2.7l-2.1,1.5c-0.9,0.6-1.8,1.9-2.1,2.8c-0.2,0.9-1.1,2.2-2,2.8l-0.4,0.3
                  			c-0.9,0.6-1.2,1.9-0.7,2.8l2.1,3.9c0.5,0.9,0.4,2.4-0.1,3.2l-1.8,2.8c-0.5,0.9-0.7,2.4-0.3,3.3l2.2,5.6c0.4,0.9,1.6,1.7,2.7,1.7
                  			h1.5c1.1,0,2.6-0.6,3.3-1.4l1.5-1.6c0.7-0.8,1.3-0.6,1.2,0.4l-0.6,12.2c-0.1,1-0.6,2.5-1.2,3.4l-0.3,0.5c-0.6,0.9-0.6,2.2,0.1,3
                  			c0.6,0.8,1.9,1,2.8,0.4l5.2-3.5c0.9-0.6,2-0.3,2.4,0.6l2.4,5.1c0.4,0.9,1.6,1.5,2.6,1.2c1-0.2,2.1-1.2,2.4-2.2l0.3-1
                  			c0.3-1,1.5-1.6,2.6-1.4l3.1,0.5c1.1,0.2,1.6,1.1,1.2,2l-2.5,5.1c-0.4,0.9-0.9,2.5-1,3.5l-0.2,1.7c-0.1,1-0.9,2.3-1.8,2.9l-4.1,2.9
                  			c-0.9,0.6-1.7,1.7-1.8,2.4c-0.1,0.7-1,1.7-2,2.2l-6.4,3.4c-1,0.5-2.2,1.7-2.7,2.6l-0.1,0.1c-0.5,0.9-1.7,1.1-2.6,0.6l-0.6-0.4
                  			c-0.9-0.6-2.5-0.7-3.5-0.2l-3.4,1.5c-1,0.4-2.5,1.4-3.3,2.1l-3.2,2.9c-0.8,0.7-2.3,1.3-3.5,1.3h-2.3c-1.1,0-2.5,0.7-3.2,1.5
                  			l-2.2,2.9c-0.6,0.8-0.4,1.9,0.6,2.5l1.2,0.6c1,0.5,2.3,0.3,3-0.4c0.7-0.8,2.2-1.2,3.2-0.9l1.9,0.5c1.1,0.3,2.1-0.3,2.2-1.3
                  			l0.1-0.6c0.1-1,1.1-1.5,2.2-1.2l1.1,0.3c1.1,0.3,2.2-0.2,2.6-1.1l0.9-2.3c0.6-0.5,8.5-2.2,8.5-2.2c1.1-0.2,2.2,0.3,2.4,1.1
                  			c0.2,0.8,1,0.9,1.6,0.1l1.6-1.9c0.7-0.8,2.1-1.7,3.2-2l1.3-0.4c1.1-0.3,2-1.4,2.1-2.4l0-0.1c0.1-1,1-2.1,2-2.4l1.4-0.4
                  			c1.1-0.3,2.7-1.1,3.6-1.6l3.2-2c0.9-0.6,2-1.8,2.3-2.8l0.2-0.5c0.4-1,1.4-2.2,2.4-2.7l7.5-4.4c0.9-0.5,2.2-1.7,2.9-2.5l4.9-6.3
                  			c0.6-0.8,0.5-2-0.4-2.7l-0.8-0.7c-0.8-0.7-1.1-2-0.7-2.9l0.8-1.5c0.5-0.9,1.7-1.9,2.8-2.1l1-0.2c1.1-0.2,2.1-1.2,2.3-2.2l0.6-3.9
                  			c0.2-1,1-2.4,1.8-3l7-5.7c0.8-0.7,2.4-1,3.5-0.7l4.9,1.3c1.1,0.3,1.2,1,0.3,1.5l-5.1,3c-0.9,0.6-2.3,1.6-3,2.4l-2.7,2.8
                  			c-0.7,0.8-0.9,2.1-0.3,3l0.6,0.9c0.6,0.9,0.5,2.2-0.2,3.1l-1.6,1.9c-0.7,0.8-0.3,1.4,0.8,1.3l4.2-0.3c1.1-0.1,2.4-0.9,2.9-1.8
                  			l2.9-5.6c0.5-0.9,1.5-1.1,2.3-0.4l0.4,0.4c0.8,0.7,2,0.6,2.6-0.2l0,0c0.7-0.8,2-1.5,2.9-1.5c1,0,1.7-0.8,1.7-1.8
                  			c0,0,0-5.8-0.1-6.1c-0.1-0.3,5.1-2,5.1-2c1-0.4,1.9,0.2,1.9,1.2l-0.1,3.9c0,1,0.8,1.6,1.9,1.3l3.9-1c1.1-0.3,2.6,0.1,3.5,0.7
                  			l4.1,3.3c0.8,0.7,2.4,1.1,3.5,0.9l7.9-1.3c1.1-0.2,2.7,0.2,3.6,0.8l2.4,1.7c0.9,0.6,2.4,0.7,3.4,0.2l2.2-1.1
                  			c1-0.5,2.2-0.2,2.7,0.7l0.9,1.6c0.5,0.9,1.7,2.1,2.6,2.6l12.9,7.5c0.9,0.5,2,0.2,2.4-0.7l1-2.4c0.4-0.9,1.1-1,1.6-0.1l0.8,1.4
                  			c0.5,0.9,1.8,1.6,2.9,1.5c1.1-0.1,2.1-0.9,2.3-1.9c0.2-0.9,1.1-1.4,2.2-1l3.9,1.4c1,0.4,2.1,1.5,2.5,2.4l2.1,6.2
                  			c0.3,1,1.3,2.2,2.3,2.8L357,735z"/>
                  		<path className="state__path" d="M157,761.6c-0.6-0.8-2-1.3-3.1-1.1l-3.6,0.7c-1.1,0.2-2.3,1.2-2.6,2.1c-0.3,0.9-1.5,1.6-2.6,1.4l-2.9-0.4
                  			c-1.1-0.2-2.3,0.5-2.7,1.4l-1.6,3.6c-0.4,0.9,0.2,1.6,1.3,1.5l9.2-0.8c1.1-0.1,2.8-0.7,3.6-1.3l5.1-3.5c0.9-0.6,1.1-1.8,0.5-2.6
                  			L157,761.6z"/>
                  		<path className="state__path" d="M130.5,766.2l-2-0.6c-1.1-0.3-2.6,0-3.4,0.7l-2.9,2.6c-0.8,0.7-0.6,1.5,0.5,1.8l4.9,1.4
                  			c1.1,0.3,2.3-0.2,2.8-1.1l1.2-2.4C132.1,767.5,131.6,766.5,130.5,766.2z"/>
                  		<path className="state__path" d="M108.4,763.7c-0.5-0.9-1.6-1.1-2.5-0.5l-4.1,3c-0.9,0.6-0.7,1.5,0.3,1.9l9.8,4.1c1,0.4,1.4,0,0.9-0.9
                  			L108.4,763.7z"/>
                  		<path className="state__path" d="M97.1,759.6l-7.8-2.2c-1.1-0.3-2.3,0.2-2.7,1.2l-0.7,1.7c-0.4,0.9,0,2.1,1,2.7l4.3,2.4
                  			c0.9,0.5,2.3,0.4,3.1-0.4l3.4-3.4C98.5,760.8,98.2,759.9,97.1,759.6z"/>
                  		<path className="state__path" d="M76.8,751l-4.1,3.4c-0.8,0.7-0.7,1.6,0.3,2.1l5.1,2.6c1,0.5,1.6,0.1,1.5-0.9l-1-6.6
                  			C78.4,750.6,77.6,750.4,76.8,751z"/>
                  		<path className="state__path" d="M71.8,744.4l-6.3-2.8c-1-0.5-1.6,0-1.4,1l1.3,5c0.3,1,1.3,1.4,2.2,0.9l4.2-2.2
                  			C72.8,745.6,72.8,744.8,71.8,744.4z"/>
                  		<path className="state__path" d="M52.8,716.7c-0.9-0.6-1.4-0.3-1.2,0.7l1.7,8.1c0.2,1,1.1,1.3,2,0.7l3.9-2.7c0.9-0.6,0.9-1.6,0-2.3L52.8,716.7
                  			z"/>
                  	</g>
                  </g>
                  <g className="state alabama">
                  	<path className="state__path" d="M753.3,524.8l1.5-4.2c12.5-1.7,46.6-6.3,53.8-7.4l-2.4-4.8l1.1-5l-3.3-5.9l1.8-3.8l-2.1-4.7l3.6-4.2l-7.1-13
                  		l-14.8-48.3l0.4-0.6c-18,2.8-37.8,5.6-55.4,8l0.2,0l4.8,5.7l-0.7,69.5l4.5,33.8l2.2-0.1c1.1-0.1,2.4-0.9,2.9-1.9l2.1-4.5
                  		c0.5-1,1.7-1.6,2.8-1.3l2.3,0.6c1.1,0.3,1.6,1.4,1.2,2.5l-1.3,3.5c-0.4,1,0.2,1.6,1.2,1.3l5.8-1.9l1.1-9L753.3,524.8z"/>
                  </g>

                </g>
              </g>
            </g>

          </svg>

          <div ref="title" className="clip-and-zoom-map__title">
            <h1 {...tabbable} className="uppercase">{title}</h1>
          </div>

        </div>
      </FullScreenContainer>
    );
  }
};

ClipAndZoomMap.propTypes = {
  image: PropTypes.string.isRequired,
  position: PropTypes.number.isRequired,
};

const animation = ({context, timeline, easeOut, easeIn}) => {
  // requestAnimationFrame( () => {
    const {mapSvg, states, map, mapMovement, mapSvgContainer, background, backgroundMask, mask, maskedImage, title} = context.refs;

    const {startOnMap} = mapSvgContainer.dataset

    const zoomTarget = mapSvgContainer.querySelector('[data-zoom-target]');
    const zoomTargetIsDc = zoomTarget.classList.contains('washington-dc')

    const {zoomAdjust=1} = zoomTarget.dataset;
    const maskTarget = mask.childNodes[0];

    const {height: targetHeight, width: targetWidth, x: targetX, y: targetY} = zoomTarget.getBoundingClientRect();
    const {height: maskHeight, width: maskWidth, x: maskX, y: maskY} = mask.getBoundingClientRect();
    const {x: mapBoundsX, y: mapBoundsY, width: mapWidth, height: mapHeight} = map.getBoundingClientRect();
    const {x: mapX, y: mapY} = mapSvg.getBoundingClientRect();

    const {innerWidth, innerHeight} = window;
    const windowAspectRatio = innerHeight / innerWidth;

    let viewBoxScale;
    if (windowAspectRatio >= viewBoxAspectRatio) {
      viewBoxScale = innerWidth / viewBoxWidth;
    } else {
      viewBoxScale = innerHeight / viewBoxHeight;
    }

    const targetStartX = (targetX - mapBoundsX);
    const targetStartY = (targetY - mapBoundsY);

    // gives us the target's center point in the window
    const originX = targetStartX + (targetWidth / 2);
    const originY = targetStartY + (targetHeight / 2);

    const movementXAmount = (innerWidth / 2);
    const movementYAmount = (innerHeight / 2);

    // gives us the target's center point within the SVG's coordinate system
    const svgTranslatedX = (targetX - mapX) + (targetWidth / 2);
    const svgTranslatedY = (targetY - mapY) + (targetHeight / 2);

    const newMapX = (movementXAmount - svgTranslatedX) / viewBoxScale; // ((mapWidth / 2) - originX) - renderedX
    const newMapY = (movementYAmount - svgTranslatedY) / viewBoxScale; // ((mapWidth / 2) - originX) - renderedX

    if (startOnMap) {

    } else {
      TweenMax.set(background, {...hideElementWithAria});
      TweenMax.set(states, {css: {strokeDasharray: 1010, strokeDashoffset: 1500}});
      TweenMax.set(mapSvgContainer, {x: 0, y: 0, ...hideElementWithAria, scale: 0.85});
    }


    TweenMax.set(states, {css: {stroke: 'white'}});
    TweenMax.set(zoomTarget, {css: {stroke: 'white'}});

    if (zoomTargetIsDc) {
      TweenMax.set(zoomTarget.childNodes[0], {...hideElementWithAria});
    }

    TweenMax.set(maskedImage, {...hideElementWithAria});
    TweenMax.set(title, {...hideElementWithAria});
    TweenMax.set(maskTarget, {scale: 1, x: 0, y: 0});

    const transformOrigin = `${100 * (originX / mapWidth)}% ${100 * (originY / mapHeight)}%`;

    TweenMax.set(map, {scale: 1, transformOrigin});
    TweenMax.set(maskTarget, {scale: 1, transformOrigin: '50% 50%'});
    TweenMax.set(mapSvg, {scale: 1});

    if (startOnMap) {

    } else {
      timeline.to(mapSvgContainer, 1, {...showElemWithAria, scale: 1, ...easeOut});
      timeline.to(states, 2, {css: {strokeDashoffset: 2020}, ...easeOut}, '-=1');
      timeline.to(background, 1, {...showElemWithAria}, "-=1");
      // console.log('-------------1',timeline.duration())
    }

    // this manually sizes the black background to hide mask cropping issues
    // created by the svg's aspect ratio
    let bgWidth;
    let bgHeight;
    if (innerHeight/innerWidth >= aspectRatio) {
      bgHeight = innerHeight;
      bgWidth = innerHeight / aspectRatio;
    } else {
      bgWidth = innerWidth;
      bgHeight = bgWidth * aspectRatio;
    }

    const widthPercentage = (bgWidth / mapWidth * 100);
    const heightPercentage = (bgHeight / mapHeight * 100);
    const bgX = `${((100 - widthPercentage) / 2)}%`;
    const bgY = `${((100 - heightPercentage) / 2)}%`;
    TweenMax.set(background, {attr: {
      width: `${widthPercentage}%`,
      height: `${heightPercentage}%`,
      x: bgX,
      y: bgY,
    }});
    TweenMax.set(backgroundMask, {attr: {
      width: `${widthPercentage}%`,
      height: `${heightPercentage}%`,
      x: bgX,
      y: bgY,
    }});

    const scaleAmount = (window.innerHeight * (0.9 * zoomAdjust)) / targetHeight;
    const fullScreenScale = (window.innerWidth / targetWidth) * 5;

    timeline.to(map, 2, {scale: scaleAmount, ease: Expo.easeInOut});
    timeline.to(mapMovement, 2, {x: newMapX, y: newMapY, ease: Expo.easeInOut}, '-=2');
    timeline.to(maskTarget, 2, {scale: scaleAmount, x: newMapX, y: newMapY, ease: Expo.easeInOut}, '-=2');
    // console.log('-------------2',timeline.duration())

    if (zoomTargetIsDc) {
      timeline.to(zoomTarget.childNodes[0], 1, {...showElemWithAria}, '-=1');
      timeline.to(states, 1, {...hideElementWithAria}, '-=1');
    } else {
      timeline.to(states, 1, {css: {stroke: 'black'}}, '-=1');
    }

    timeline.to([title,maskedImage], 1, {...showElemWithAria, ...easeOut}, '-=1');
    timeline.to(map, 1.5, {scale: fullScreenScale, ease: Expo.easeInOut});
		timeline.to(maskTarget, 1.5, {scale: fullScreenScale, ease: Expo.easeInOut}, "-=1.5")
    // console.log('-------------3',timeline.duration())

		timeline.to(title, 1, {...hideElementWithAria}, '-=1');
    timeline.to(mapSvg, 1, {...hideElementWithAria}, "-=1");

    placeholder(timeline, 2);
    // console.log('-------------4',timeline.duration())
  // });
};

export default Timeline(ClipAndZoomMap, {animation, cache: true});
