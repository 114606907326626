import React from 'react';
import {TweenMax} from 'gsap';
import {useStaticQuery, graphql} from 'gatsby';
import VideoIntroduction from 'components/VideoIntroduction';
import videoSrc from './videos/introduction-intro-no_text.mp4';

const texts = [
  {
    time: {
      enter: 2.5, //in seconds,
      leave: 6.5, //in seconds
    },
    text: "There was never a time when we were without fear."
  },
  {
    time: {
      enter: 7, //in seconds,
      leave: 9.5, //in seconds
    },
    text: "There was never a time when we didn’t think that we might seriously get hurt,"
  },
  {
    time: {
      enter: 10, //in seconds,
      leave: 12.5, //in seconds
    },
    vertAlignment: "center",
    horzAlignment: "center",
    text: "We might get killed.",
    speed: 'slow',
    attribution: {
      name: "Charles Bonner",
      title: "Student"
    }
  },
  {
    time: {
      enter: 14, //in seconds,
      leave: 16.5, //in seconds
    },
    vertAlignment: "center",
    text: "I asked my mother had she ever voted. And she told me "
  },
  {
    time: {
      enter: 17, //in seconds,
      leave: 21, //in seconds
    },
    vertAlignment: "center",
    text: "“Don’t you never say that out in the street..."
  },
  {
    time: {
      enter: 21.5, //in seconds,
      leave: 24.5, //in seconds
    },
    horzAlignment: "center",
    vertAlignment: "center",
    text: "You don’t say nothing like that"
  },
  {
    time: {
      enter: 24.5, //in seconds,
      leave: 27, //in seconds
    },
    horzAlignment: "center",
    vertAlignment: "center",
    speed: 'slow',
    text: "...Cause you’ll get in trouble”",
    attribution: {
      name: "Terry Shaw ",
      title: "Student"
    }
  }
]

class IntroductionVideo extends React.Component {
  render() {
    return (
      <VideoIntroduction
        src={videoSrc}
        texts={texts}
      />
    );
  }
}

export default IntroductionVideo
