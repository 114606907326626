import React from 'react';
import PropTypes from 'prop-types';
import FullScreenContainer from 'components/FullScreenContainer';
import '../styles/templates/center-view.scss';

const CenterView = class extends React.Component {
  render() {
    const {
      content,
      background,
    } = this.props;

    return (
      <FullScreenContainer>
        <div className="center-view__image">
          {background ? background : <div className="empty-bg"></div>}
        </div>
        <div className="center-view__content view-content">
          {content}
        </div>
      </FullScreenContainer>
    );
  }
};

CenterView.propTypes = {
  content: PropTypes.object,
  background: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

export default CenterView;
