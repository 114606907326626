import React from 'react';
import {TweenMax} from 'gsap';
import {useStaticQuery, graphql} from 'gatsby';
import Modal from 'components/ChapterModal';
import { Image } from 'components/Image';
import timeline from 'components/timelineComponent';

import CenterView from 'templates/CenterView';
import SplitView from 'templates/SplitView';

import './introduction-scene-1.scss';
import {hideAllRefs, placeholder, showElemWithAria, hideElementWithAria, hideFromBelow, revealFromBelow} from 'utils/animation';
import {tabbable} from 'utils/accessibility'

import scene from 'components/scene';

import FullScreenContainer from 'components/FullScreenContainer';
import { stickyPositions, modalBackgrounds } from 'components/ModalContent';

const RightToVoteModal = props => (
  <div className="right-to-vote__content">
    <div className="right-to-vote__text">
      <h3 className="uppercase">
        US Voting Access <span className="highlight">in 2018</span>
      </h3>
      <div className="right-to-vote__inline">
        <div className="right-to-vote__infographic"> </div>
        <div className="right-to-vote__inline__text">
          <span className="alt">
            12 states advanced bills <span>expanding</span> voting access
          </span>
        </div>
      </div>
      <div className="right-to-vote__inline">
        <div className="right-to-vote__infographic red"> </div>
        <div className="right-to-vote__inline__text">
          5 states advanced bills <span className="highlight">restricting</span>{' '}
          voting access
        </div>
      </div>
      <div className="right-to-vote__inline">
        <div className="right-to-vote__infographic stripe"> </div>
        <div className="right-to-vote__inline__text">
          Bills both <span>expanding</span> and{' '}
          <span className="highlight">restricting</span>
        </div>
      </div>
    </div>
    <div className="right-to-vote__image">
      <Image image={props.image} alt={props}/>
    </div>
  </div>
);

class IntroS1 extends React.Component {
  render() {
    const {
      studentsBackground,
      studentsRightToVoteBackground,
      justiceRoberts,
      infographic,
      raphaelWarnock,
      quote,
    } = this.props.data;

    return (
      <div className="introduction-scene-1">
        <div className="call-to-action" ref="callToAction">
          <CenterView
            background={
              <div ref="callToActionBg">
                <div className="call-to-action__background">
                  <Image image={studentsBackground} alt="students background" sizes="100vw" />
                </div>
              </div>
            }
            content={
              <FullScreenContainer className="cta-full">
                <div className="cta-content" ref="callToActionContent">
                  <p {...tabbable} className="subhead bg-text" ref="callToActionFGLesson">
                    Selma Online is more than a history lesson.
                  </p>
                  <p {...tabbable} className="subhead cta-text-2" ref="callToActionFGAction">
                    It’s a call to action to learn about
                    <br />
                    <span className="highlight">democracy</span>,{' '}
                    <span className="highlight">civil rights</span>, and the
                    need to safeguard{' '}
                    <span className="highlight">voting equality</span>
                  </p>
                  <p {...tabbable} className="subhead bottom-text" ref="callToActionFGen">
                    …in every generation.
                  </p>
                </div>
              </FullScreenContainer>
            }
          />
        </div>
        <div className="voting-discrimination" ref="votingDiscrimination">
          <SplitView
            leftPanel={
              <div>
                <div
                  className="voting-discrimination__background"
                  ref="votingDiscriminationBackground">
                  <Image image={studentsBackground} alt="students background" sizes="100vw" />
                </div>
                <div
                  className="voting-discrimination__foreground"
                  ref="votingDiscriminationForeground" {...tabbable} role='img' aria-label="WASHINGTON, DC - JANUARY 28: U.S. Supreme Court Chief Justice John Roberts arrives prior to President Barack Obama's State of the Union speech on Capitol Hill on January 28, 2014 in Washington, DC. In his fifth State of the Union address, Obama is expected to emphasize on healthcare, economic fairness and new initiatives designed to stimulate the U.S. economy with bipartisan cooperation. (Photo by Larry Downing-Pool/Getty Images)">
                  <Image image={justiceRoberts} alt="WASHINGTON, DC - JANUARY 28: U.S. Supreme Court Chief Justice John Roberts arrives prior to President Barack Obama's State of the Union speech on Capitol Hill on January 28, 2014 in Washington, DC. In his fifth State of the Union address, Obama is expected to emphasize on healthcare, economic fairness and new initiatives designed to stimulate the U.S. economy with bipartisan cooperation. (Photo by Larry Downing-Pool/Getty Images)" sizes="40vw" />
                </div>
              </div>
            }
            rightPanel={
              <div
                className="introduction-scene-1__text quote-panel content"
                {...tabbable}
                ref="votingDiscriminationContent">
                <div ref="votingDiscriminationQuote" className="quote">
                  <Image image={quote} alt="quote" />
                </div>
                <h2 ref="votingDiscriminationText1">
                  Voting discrimination still exists; no one doubts that.
                </h2>
                <div ref="votingDiscriminationText2" className="label voting-discrimination__landmark">
                  Chief Justice John Roberts
                </div>
                <div ref="votingDiscriminationText3" className="caption">Shelby v. Holder (U.S. Supreme Court, 2013).</div>
              </div>
            }
          />
        </div>
        <div className="right-to-vote" ref="rightToVote">
          <CenterView
            background={<Image image={studentsRightToVoteBackground} alt="right to vote" />}
            content={
              <div
                className="introduction-scene-1__text intro center"
                {...tabbable}
                ref="rightToVoteContent">
                  <p ref="rightToVoteText1">
                    In American history,
                    <br />
                    the <span className="highlight">right to vote</span> has
                    always been contested.
                  </p>
                  <p ref="rightToVoteText2">
                    Some groups have struggled to expand that right.
                  </p>
                  <p ref="rightToVoteText3">
                    Others have fought to limit it.
                  </p>

                <Modal
                  className="right-to-vote__modal"
                  stickyPosition={stickyPositions.NO_STICKY}
                  modalBackground={modalBackgrounds.TRANSPARENT}>
                  <RightToVoteModal image={infographic} alt="Infographic"/>
                </Modal>
              </div>
            }
          />
        </div>
        <div className="raphael-warnock" ref="raphaelWarnock">
          <SplitView
            leftPanel={
              <div
                className="introduction-scene-1__text content quote-panel"
                {...tabbable}
                ref="raphaelWarnockContent">
                <div ref="raphaelWarnockQuote" className="quote">
                  <Image image={quote} alt="quote"/>
                </div>
                <h4 ref="raphaelWarnockText1">
                  We have witnessed over the last few years, the worst assault
                  on voting rights since the Voting Rights Act was passed in
                  1965...”
                </h4>
                <div ref="raphaelWarnockText2" className="label raphael-warnock__landmark">
                  Rev. Raphael Warnock
                </div>
                <div ref="raphaelWarnockText3" className="caption">
                  Leader, Ebenezer Baptist Church
                  <br />
                  in Atlanta. (2015)
                </div>
              </div>
            }
            rightPanel={
              <div>
                <div
                  className="raphael-warnock__background"
                  ref="raphaelWarnockBackground"
                />
                <div
                  className="raphael-warnock__foreground"
                  ref="raphaelWarnockForeground" {...tabbable} aria-label="ATLANTA, GEORGIA - JANUARY 21: Rev. Raphael G. Warnock, Senior Pastor of Ebenezer Baptist Church speaks onstage during 2019 Martin Luther King, Jr. Annual Commemorative Service at Ebenezer Baptist Church on January 21, 2019 in Atlanta, Georgia. (Photo by Paras Griffin/Getty Images)">
                  <Image image={raphaelWarnock} sizes="100vw" alt="ATLANTA, GEORGIA - JANUARY 21: Rev. Raphael G. Warnock, Senior Pastor of Ebenezer Baptist Church speaks onstage during 2019 Martin Luther King, Jr. Annual Commemorative Service at Ebenezer Baptist Church on January 21, 2019 in Atlanta, Georgia. (Photo by Paras Griffin/Getty Images)"/>
                </div>
              </div>
            }
          />
        </div>
      </div>
    );
  }
}

const animation = ({context, timeline, easeOut}) => {
  const {
    callToActionContent,
    callToActionBg,
    callToActionFGLesson,
    callToActionFGAction,
    callToActionFGen,
    votingDiscrimination,
    votingDiscriminationBackground,
    votingDiscriminationForeground,
    votingDiscriminationContent,
    votingDiscriminationQuote,
    votingDiscriminationText1,
    votingDiscriminationText2,
    votingDiscriminationText3,
    rightToVote,
    rightToVoteContent,
    rightToVoteText1,
    rightToVoteText2,
    rightToVoteText3,
    raphaelWarnock,
    raphaelWarnockContent,
    raphaelWarnockBackground,
    raphaelWarnockForeground,
    raphaelWarnockQuote,
    raphaelWarnockText1,
    raphaelWarnockText2,
    raphaelWarnockText3
  } = context.refs;

  const rightToVoteModal = '.right-to-vote__modal'

  hideAllRefs(context.refs, [
    'callToAction', 'callToActionContent',
  ]);

  const {height} = callToActionContent.getBoundingClientRect()
  TweenMax.set([callToActionContent], {y: height / 2});
  // TweenMax.set([votingDiscriminationForeground, raphaelWarnockForeground], {y: window.innerHeight / 5});
  // TweenMax.set([votingDiscriminationContent, raphaelWarnockContent], {height: '0%'});
  TweenMax.set([
    votingDiscriminationForeground,
    votingDiscriminationContent,
    votingDiscriminationQuote,
    votingDiscriminationText1,
    votingDiscriminationText2,
    votingDiscriminationText3,
    rightToVoteContent,
    rightToVoteText1,
    rightToVoteText2,
    rightToVoteText3,
    rightToVoteModal,
    raphaelWarnockForeground,
    raphaelWarnockContent,
    raphaelWarnockQuote,
    raphaelWarnockText1,
    raphaelWarnockText2,
    raphaelWarnockText3,
  ], {...hideFromBelow})


  timeline.to(callToActionFGLesson, 1, {...showElemWithAria, onStart: () => {
    requestAnimationFrame(() => callToActionFGLesson.focus() )
  }});
  placeholder(timeline, 0.5)
  timeline.to(callToActionContent, 1.5, {y: `-=${height / 2}`, ease: Expo.easeInOut});
  timeline.to(callToActionFGLesson, 1, {className: '+=opaque'}, '-=1.5');
  timeline.to(callToActionFGAction, 1, {...showElemWithAria}, '-=1');
  placeholder(timeline, 1)
  timeline.to(callToActionFGen, 1, {...showElemWithAria});
  timeline.to(callToActionBg, 1, {...showElemWithAria}, '-=1');

  timeline.markers.createTimelineMarker();

  timeline.to(callToActionContent, 0.5, {...hideElementWithAria});
  timeline.to(votingDiscrimination, 0.5, {...showElemWithAria}, '-=0.5');
  timeline.to(votingDiscriminationForeground, 1.5, {...revealFromBelow, ...easeOut}, '-=0.25');
  timeline.to(votingDiscriminationContent, 1.5, {...revealFromBelow, ...easeOut}, '-=1.5');
  timeline.to(votingDiscriminationQuote, 1.5, {...revealFromBelow, ...easeOut}, "-=1.25")
  timeline.to(votingDiscriminationText1, 1.5, {...revealFromBelow, ...easeOut}, "-=1.5")
  timeline.to(votingDiscriminationText2, 1.5, {...revealFromBelow, ...easeOut}, "-=1.25")
  timeline.to(votingDiscriminationText3, 1.5, {...revealFromBelow, ...easeOut}, "-=1.25")

  timeline.markers.createTimelineMarker();

  timeline.to([
    callToActionBg,
    votingDiscriminationBackground,
    votingDiscrimination,
    votingDiscriminationForeground,
    votingDiscriminationContent
  ], 1, {...hideElementWithAria});

  timeline.to(rightToVote, 1, {...showElemWithAria}, "-=0.5");
  timeline.to(rightToVoteContent, 1.5, {...revealFromBelow, ...easeOut}, "-=0.5");
  timeline.to(rightToVoteText1, 1.5, {...revealFromBelow, ...easeOut}, "-=1.25");
  timeline.to(rightToVoteText2, 1.5, {...revealFromBelow, ...easeOut}, "-=1.25");
  timeline.to(rightToVoteText3, 1.5, {...revealFromBelow, ...easeOut}, "-=1.25");
  timeline.to(rightToVoteModal, 1.5, {...revealFromBelow, ...easeOut}, "-=1.25");

  timeline.markers.createTimelineMarker();

  timeline.to(rightToVoteContent, 0.5, {...hideElementWithAria});

  timeline.to(raphaelWarnock, 0.5, {...showElemWithAria}, "-=0.25");
  timeline.to(raphaelWarnockForeground, 1.5, {...revealFromBelow, ...easeOut}, '-=0.25');
  timeline.to(raphaelWarnockContent, 1.5, {...revealFromBelow, ...easeOut}, '-=1.5');
  timeline.to(raphaelWarnockQuote, 1.5, {...revealFromBelow, ...easeOut}, "-=1.25")
  timeline.to(raphaelWarnockText1, 1.5, {...revealFromBelow, ...easeOut}, "-=1.5")
  timeline.to(raphaelWarnockText2, 1.5, {...revealFromBelow, ...easeOut}, "-=1.25")
  timeline.to(raphaelWarnockText3, 1.5, {...revealFromBelow, ...easeOut}, "-=1.25")

  timeline.markers.createTimelineMarker();

  timeline.to(raphaelWarnockForeground, 0.5, {...hideElementWithAria});
  timeline.to(raphaelWarnockBackground, 0.5, {...hideElementWithAria},"-=0.5");
  timeline.to(raphaelWarnockContent, 0.5, {...hideElementWithAria},"-=0.5");
  timeline.to(raphaelWarnock, 0.5, {...hideElementWithAria},"-=0.5");
  timeline.to(rightToVote, 0.5, {...hideElementWithAria},"-=0.5");

  ;
};

const query = graphql`
  query {
    studentsBackground: file(
      relativePath: {
        eq: "introduction/scene-1/images/intro-image-selma-students-background@2x.png"
      }
    ) {
      ...largeImage
    }
    studentsRightToVoteBackground: file(
      relativePath: {
        eq: "introduction/scene-1/images/intro-image-right_to_vote_sides.jpg"
      }
    ) {
      ...largeImage
    }
    justiceRoberts: file(
      relativePath: {
        eq: "introduction/scene-1/images/intro-quote-chief_justice_roberts.png"
      }
    ) {
      ...largeImage
    }
    infographic: file(
      relativePath: {
        eq: "introduction/scene-1/images/intro-image-us-map-infographic@2x.png"
      }
    ) {
      ...largeImage
    }
    raphaelWarnock: file(
      relativePath: {
        eq: "introduction/scene-1/images/intro-quote-rev_warwick.png"
      }
    ) {
      ...largeImage
    }
    studentsBackgroundRight: file(
      relativePath: {
        eq: "introduction/scene-1/images/intro-image-selma-students-background-right.png"
      }
    ) {
      ...largeImage
    }
    quote: file(relativePath: { eq: "chapter-2/scene-9/images/quote@2x.png" }) {
      ...largeImage
    }
  }
`;

const Component = scene(timeline(IntroS1, { animation }), {stopAtEnd: false});
export default props => {
  const data = useStaticQuery(query);
  return <Component data={data} {...props} />;
};
