import React from 'react';

import './democracy-modal.scss';

const DemocracyModal = () => (
  <div className="democracy">
    <h1>Democracy</h1>
    <h2>/dəˈmäkrəsē/</h2>
    <div className="democracy__definitions">
      <p className="democracy__definition">
        <span className="bold">A: </span>
        <span>government by the people; especially: rule of the majority</span>
      </p>
      <p className="democracy__definition">
        <span className="bold">B: </span>
        <span>a government in which the supreme power is vested in the people and exercised by them directly or indirectly through a system of representation usually involving periodically held free elections</span>
      </p>
    </div>
  </div>
);

export default DemocracyModal;
