import React, {Component} from 'react';
import {useStaticQuery, graphql} from 'gatsby';

import timeline from 'components/timelineComponent';
import {TweenMax, Expo} from 'gsap';
import {Image} from 'components/Image';

import CenterView from 'templates/CenterView';
import SplitView from 'templates/SplitView';
import './introduction-scene-4.scss';
import {getPercentageBetweenBounds} from 'utils';
import {
  placeholder, hideAllRefs, showElemWithAria, hideElementWithAria, easeOutQuad, revealFromBelow, hideFromBelow
} from 'utils/animation';
import {tabbable} from 'utils/accessibility'

import PathReveal from 'components/PathReveal';
import scene from 'components/scene';

// determined by logging out timeline.duration() at end of animation
const duration = 22.5

class IntroS4 extends Component {
  render() {
    const {
      position,
      data
    } = this.props
    const {
      quote1Bg,
      quote2Bg,
      quote3Bg,
      line,
    } = data;


    const line1Start = 0.33
    const line1End = 1 - line1Start
    const line1Progress = getPercentageBetweenBounds(
      position,
      (2.5 / duration),
      (7.5 / duration)
    )
    const line1Position = line1Start + (easeOutQuad(line1Progress) * line1End)

    const line2Start = 0.45
    const line2End = 1 - line2Start
    const line2Progress = getPercentageBetweenBounds(
      position,
      (10 / duration),
      (15 / duration)
    )
    const line2Position = line2Start + (easeOutQuad(line2Progress) * line2End)

    const line3Start = 0.5
    const line3End = 1 - line3Start
    const line3Progress = getPercentageBetweenBounds(
      position,
      (16.5 / duration),
      (21 / duration)
    )
    const line3Position = line3Start + (easeOutQuad(line3Progress) * line3End)

    return (
      <div className="introduction-scene-4">
        <div className="slide slide1" ref="slide1">
          <CenterView
            background={<div ref="speechPressAssemblyBackground" />}
            content={
              <div>
                <p {...tabbable} className="line-text">
                  With it, ordinary citizens can achieve extraordinary things.
                </p>
                <div className="line-container">
                  <Image image={line} alt="Doodle line"/>
                  <div ref="lineCover" className="line-cover" />
                </div>
              </div>
            }
          />
        </div>
        <div className="slide slide2" ref="slide2">
          <CenterView
            background={
              <div className="introduction-scene-4__image" ref="quote1Bg">
                <Image className="img__background" image={quote1Bg} alt="Image quote Bg"/>
              </div>
            }
            content={
              <div className="content-container">
                <div ref="slide2Path" className="animated-path-container">
                  <PathReveal
                    viewBox="0 0 1440 157"
                    position={line1Position}
                    constant={160}
                    strokeWidth={10}
                    path={`M0,45.98c506.14-0.28,759.98-0.42,761.51-0.42l72.4-0.27c11.18-0.05,28.94,2.99,39.47,6.72L984.16,91.5
                  c10.53,3.74,28.21,5.56,39.29,4.02l60.78-8.41c11.09-1.54,14.1-9.66,6.73-18.06l-22.83-25.94c-7.38-8.4-4.89-18.55,5.55-22.57
                  l29.74-11.46c10.43-4.02,25.93-1.38,34.45,5.89l54.34,46.35c8.53,7.26,18.63,21.78,22.48,32.29l5.35,14.56
                  c3.85,10.48,15.92,21.14,26.8,23.67l42.13,9.81c10.88,2.52,27.94,0.44,37.91-4.65l41.74-21.31c9.96-5.1,25.22-3.51,33.9,3.54
                  l35.63,28.78c0.59,0.48,1.2,0.92,1.84,1.34`}
                  />
                </div>
                <div className="text-container" ref="slide2Text">
                  <p {...tabbable} ref="slide2Text1" className='h3'>"We were 14,15,16 years old</p>
                  <p {...tabbable} ref="slide2Text2" className="large mb h2">
                    We didn't have a clue we were making history.
                  </p>
                  <p {...tabbable} ref="slide2Text3" className="large h2">
                    We were just trying to correct wrongs.
                  </p>
                  <p {...tabbable} ref="slide2Text4" className='h3'>Trying to make some wrongs, right."</p>
                  <div {...tabbable} ref="slide2Text5" className="name-container">
                    <p className="name h3">Bette Mae Fikes</p>
                    <p className="title h3">Student</p>
                  </div>
                </div>
              </div>
            }
          />
        </div>
        <div className="slide slide3" ref="slide3">
          <SplitView
            fullWidthPanel={
              <div className="introduction-scene-4__image" ref="quote2Bg">
                <Image className="img__background" image={quote2Bg} alt="Image quote 2 Bg"/>
              </div>
            }
            leftPanel={
              <div ref="slide3Path">
                <PathReveal
                  viewBox="0 0 418 900"
                  position={line2Position}
                  reverse
                  usePixels={true}
                  constant={2470}
                  strokeWidth={12}
                  path={`M267.9,900l13.8-14.17c7.79-8.01,15.06-23.67,16.15-34.8l6.04-61.91c1.11-11.13-4.36-13.66-12.13-5.62
                 l-59.23,26.77c-4.98,5.14-14.7,10.83-21.63,12.63l-18.8,4.9c-6.93,1.81-11.35-2.43-9.83-9.42l2.26-10.35
                 c1.52-6.99-0.06-16.49-3.52-21.12c-3.46-4.62-0.5-9.33,6.57-10.45l43.07-3.63c7.07-1.12,14.89-7.54,17.35-14.25l4.37-11.85
                 c2.47-6.71,13.43-24.36,19.94-27.68c6.49-3.32,10.73-15.1,9.46-26.22l-2.5-21.26c-1.31-11.11-11.44-21.33-22.53-22.71l-77.98-14.02
                 c-7.09-0.88-8.3-5.25-2.71-9.68l18.8-14.82c5.62-4.44,10.27-13.92,10.33-21.08l0.11-10.02c0.06-7.16,4.35-8.96,9.53-4.03
                 l84.02,52.24c8.09,7.71,13.23,5,11.4-6.02l-11.08-67.5c-1.8-11.02-9.47-26.8-17.04-35.04l-35.04-38.19l-52.73-57.49
                 c-7.56-8.25-21.33-9.88-30.62-3.65l-61.16,41.09c-9.29,6.23-24.93,7-34.78,1.73l-30.88-16.63c-9.85-5.27-20.54-18.37-23.77-29.08
                 l-5.52-18.42c-3.23-10.71,3.29-19.69,14.47-19.95l45.37-1.12c11.18-0.28,15.39-8.22,9.34-17.63l-3.62-5.62
                 c-6.04-9.41-6.51-21.71-1.02-27.36c5.46-5.62,4.78-17.79-1.52-27.01l-8.36-12.22c-6.31-9.23-8.98-25.59-5.92-36.35l4.33-15.4
                 c3.06-10.76-2.28-24.31-11.85-30.09L28,192.9c-5.75-3.47-7.59-8.19-6.4-12.67c0.79-2.96,2.7-5.86,6.55-8.1l6.73-3.92
                 c9.66-5.63,13.91-18.63,9.45-28.87L23.37,91.19c-4.46-10.24-0.47-13.58,8.87-7.45l108.8,71.76c3.92,2.58,7.21,6.72,9.9,11.3
                 c3.66,6.35,5.97,13.68,5.43,20.15l-11.39,142.63c-0.91,11.14,7.5,19.76,18.65,19.12l21.36-1.21c11.16-0.63,22.06-10.11,24.21-21.08
                 l11.43-58.17c2.15-10.96,7.78-28.22,12.49-38.38l26.68-57.34c4.72-10.16,9.5-27.55,10.67-38.66l0.75-7.24l8.78-84.75
                 c0.81-7.79-0.77-18.4-3.63-27.47c-1.21-3.88-2.61-7.54-4.24-10.46L269.91,0`}
                />
              </div>
            }
            rightPanel={
              <div ref="slide3Text">
                <p {...tabbable} ref="slide3Text1" className='h3 small'>"It hit us that</p>
                <p {...tabbable} ref="slide3Text2" className="h2 large">we had a voice,</p>
                <p {...tabbable} ref="slide3Text3" className='h3 small'>you know. We had a voice."</p>
                <div {...tabbable} ref="slide3Text4" className="name-container">
                  <p className="name h3">Terry Shaw</p>
                  <p className="title h3">Student</p>
                </div>
              </div>
            }
          />
        </div>
        <div className="slide slide4" ref="slide4">
          <SplitView
            fullWidthPanel={
              <div className="introduction-scene-4__image" ref="quote3Bg">
                <Image className="img__background" image={quote3Bg} alt="Image Quote 3 Bg"/>
              </div>
            }
            leftPanel={
              <div ref="slide4Text">
                <p {...tabbable} ref="slide4Text1" className="students-sit__text h3">
                  “When Dr King spoke and said it was time to move, me and some
                  of my classmates, some of my young friends, were saying 'you
                  know? Alright.'
                </p>
                <p {...tabbable} ref="slide4Text2" className="large h2">We were ready"</p>
                <div {...tabbable} ref="slide4Text3" className="name-container">
                  <p className="name">Lynda Blackmon</p>
                  <p className="title">Student</p>
                </div>
              </div>
            }
            rightPanel={
              <div className="slide-4-path" ref="slide4Path">
                <PathReveal
                  viewBox="0 0 918 680"
                  position={line3Position}
                  constant={270}
                  strokeWidth={12}
                  path={`M686,78.77C597.37,55.47,508.63,31.38,420.44,6.6v0.01l-18.95,56.25c-1.67,9.93,5.16,17.21,15.17,16.21
                  l7.29-0.75c10.02-1.01,18.22,6.4,18.22,16.46v17.43c0,10.06-8.02,20.19-17.81,22.48l-3.45,0.82c-9.8,2.31-16.58,12.33-15.07,22.29
                  l-1.48,37.06c1.23,8.05,8.55,12.54,16.28,9.95l9.77-3.27c7.73-2.58,10.64-2.29,12.92,5.52l3.27,11.3
                  c2.28,7.83,0.09,19.53-4.87,26.01l-31.06,55.43c-4.96,6.47-15.2,14.22-22.79,17.22l-32.42,20.72c-7.59,3-9.61-0.38-9.61-8.54v-6.79
                  c0-8.15,4.36-19.85,9.72-26l23.34-21.49c5.35-6.15,3.79-20.9-4.22-19.4l-40.36,7.59c-8.01,1.5-13.97-3.91-13.24-12.02l0.67-3.35
                  c0.73-8.13,7.7-16.69,15.51-19.04l27.71-8.34c7.8-2.35,13.26-10.88,12.14-18.94l-8.57-19.91c-1.38-9.97-10.74-17.63-20.79-17.03
                  l-20.31,1.21c-10.05,0.58-24.53-4.25-32.19-10.78l-82.5-67.24c-8.5-7.25-55.03-21.65-64.35-22.89c-9.31-1.22-19.36,6.6-22.33,17.37
                  l-19.73,51.96c-2.97,10.78-4.47,28.71-3.33,39.83l8.64,84.28c1.13,11.12-1.04,28.82-4.83,39.35L84,360.93
                  c-3.79,10.53-2.74,27.01,2.33,36.64c5.07,9.64,16.43,11.92,25.28,5.06l25.62-19.84c8.85-6.86,15.35-3.34,14.45,7.8l-2.74,34.17
                  c-0.88,11.15-8.56,26.22-17.05,33.48l-22.4,19.21C101,484.73,91.22,499.38,87.77,510l-1.1,3.34c-3.45,10.64,1.7,23.8,11.45,29.26
                  l29.92,16.74c9.75,5.47,15.06,18.67,11.78,29.36l-2.22,7.3l-6.65,21.69c-3.28,10.69-13.8,14.74-23.41,9.03l-26.97-16.06
                  c-9.59-5.72-20.17-1.67-23.48,9.01L15.81,752.36c-2.18,7-5.57,16.8-8.84,25.64`}
                />
              </div>
            }
          />
        </div>
      </div>
    );
  }
}

const animation = ({context, timeline, easeOut}) => {


  const {
    quote1Bg,
    quote2Bg,
    slide1,
    slide2,
    slide3,
    slide4,
    lineCover,
    slide2Text,
    slide2Text1,
    slide2Text2,
    slide2Text3,
    slide2Text4,
    slide2Text5,
    slide3Text,
    slide3Text1,
    slide3Text2,
    slide3Text3,
    slide3Text4,
    slide4Text,
    slide4Text1,
    slide4Text2,
    slide4Text3,
    slide2Path,
    slide3Path,
    slide4Path
  } = context.refs;

  hideAllRefs(context.refs, ['lineCover', 'quote1Bg', 'quote2Bg'])

  TweenMax.set([
    slide2Text1,
    slide2Text2,
    slide2Text3,
    slide2Text4,
    slide2Text5,
    slide3Text1,
    slide3Text2,
    slide3Text3,
    slide3Text4,
    slide4Text1,
    slide4Text2,
    slide4Text3,
  ], {...hideFromBelow})

  timeline.to(slide1, 0.25, { ...showElemWithAria });
  placeholder(timeline, 0.5)

  timeline.to(lineCover, 1.5, { x: '100%', ease: Expo.easeInOut });

  timeline.markers.createTimelineMarker()

  timeline.to(slide1, 0.5, { ...hideElementWithAria });
  timeline.to(slide2, 0.5, { ...showElemWithAria }, "-=0.25");
  timeline.to(slide2Path, 1, { ...showElemWithAria }, "-=0.5");
  timeline.to(slide2Text, 0.5, { ...showElemWithAria }, '-=0.5');

  timeline.to(slide2Text1, 1.5, {...revealFromBelow, ...easeOut})
  timeline.to(slide2Text2, 1.5, {...revealFromBelow, ...easeOut}, "-=0.5")
  timeline.to(slide2Text3, 1.5, {...revealFromBelow, ...easeOut})
  timeline.to(slide2Text4, 1.5, {...revealFromBelow, ...easeOut}, "-=0.5")
  timeline.to(slide2Text5, 1.5, {...revealFromBelow, ...easeOut})

  timeline.markers.createTimelineMarker()

  timeline.to(slide2, 0.5, { ...hideElementWithAria });
  timeline.to(slide3, 0.5, { ...showElemWithAria }, '-=0.25');
  // console.log(timeline.duration())
  timeline.to(slide3Path, 1, { ...showElemWithAria }, "-=0.5");
  timeline.to(slide3Text, 0.5, { ...showElemWithAria }, '-=0.5');

  timeline.to(slide3Text1, 1.5, {...revealFromBelow, ...easeOut})
  timeline.to(slide3Text2, 1.5, {...revealFromBelow, ...easeOut}, "-=1.25")
  timeline.to(slide3Text3, 1.5, {...revealFromBelow, ...easeOut})
  timeline.to(slide3Text4, 1.5, {...revealFromBelow, ...easeOut})
  // console.log(timeline.duration())

  timeline.markers.createTimelineMarker()

  timeline.to(slide3, 0.5, { ...hideElementWithAria });
  timeline.to(slide4, 0.5, { ...showElemWithAria }, '-=0.25');
  // console.log(timeline.duration())
  timeline.to(slide4Path, 1, { ...showElemWithAria }, "-=0.5");
  // console.log(timeline.duration())
  timeline.to(slide4Text, 0.5, { ...showElemWithAria }, '-=0.5');
  timeline.to(slide4Text1, 1.5, {...revealFromBelow, ...easeOut})
  placeholder(timeline, 0.5);
  timeline.to(slide4Text2, 1.5, {...revealFromBelow, ...easeOut})
  timeline.to(slide4Text3, 1.5, {...revealFromBelow, ...easeOut})


  timeline.markers.createTimelineMarker();
  // console.log(timeline.duration())
};

const query = graphql`
  query {
    quote1Bg: file(
      relativePath: { eq: "introduction/scene-4/images/intro-image-quote-01-bg.jpg" }
    ) {
      ...mediumImage
    }
    quote2Bg: file(
      relativePath: { eq: "introduction/scene-4/images/intro-image-quote-02-bg.jpg" }
    ) {
      ...mediumImage
    }
    quote3Bg: file(
      relativePath: { eq: "introduction/scene-4/images/intro-image-quote-03-bg.jpg" }
    ) {
      ...mediumImage
    }
    line: file(
      relativePath: { eq: "introduction/scene-4/images/intro-doodle-line_draw.png" }
    ) {
      ...smallImage
    }
  }
`;

const SceneComponent = scene(timeline(IntroS4, {animation}));
export default (props) => {
  const data = useStaticQuery(query);
  return <SceneComponent data={data} {...props} />;
};
