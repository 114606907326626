import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import {TweenMax} from 'gsap';
import {getPercentageBetweenBounds} from 'utils';
import {placeholder} from 'utils/animation';
;
import FullScreenContainer from 'components/FullScreenContainer';
import ClipAndZoomMap from 'components/ClipAndZoomMap';
import ChapterConclusion from 'components/ChapterConclusion';
import scene from 'components/scene';
import timeline from 'components/timelineComponent';

import './introduction-scene-5.scss';

const IntroS5 = class extends React.Component {
  render() {
    const {position} = this.props;
    const {bgImage} = this.props.data;

    const zoomPosition = getPercentageBetweenBounds(position, 0.5/5.5, 1);

    return (
      <FullScreenContainer className="intro-scene-5">
        <div ref="section1" className="intro-scene-5__section">
          <ClipAndZoomMap
            position={zoomPosition}
            startOnMap
            state="alabama"
            title="Selma"
            image={bgImage.childImageSharp.fluid.src}
          />
        </div>
        <div className='chapter-end' ref="chapterEnd">
          <ChapterConclusion
            image={bgImage.childImageSharp.fluid.src}
            chapterNumber={0}
            nextChapterTitle={
              <div>
                Race &<br />
                Voting Rights
              </div>
            }
          />
        </div>
      </FullScreenContainer>
    );
  }
};

const animation = ({context, timeline, easeOut}) => {
  const {chapterEnd} = context.refs;

  TweenMax.set([chapterEnd], {opacity: 0});
  placeholder(timeline, 5.5);
  timeline.to(chapterEnd, 1, {opacity: 1}, "-=1");
  timeline.markers.createTimelineMarker();
  ;
};

const query = graphql`
  query {
    bgImage: file(
      relativePath: { eq: "introduction/scene-5/images/intro-image-voting-signs-background@2x.jpg" }
    ) {
      ...largeImage
    }
  }
`;

const SceneComponent = scene(timeline(IntroS5, {animation}));
export default (props) => {
  const data = useStaticQuery(query);
  return <SceneComponent data={data} {...props} />;
};
