import React from 'react';
import PropTypes from 'prop-types';
import TweenMax, {Linear} from 'gsap/TweenMax';
import timeline from 'components/timelineComponent';

const PathReveal = class extends React.Component {
  render() {
    const { constant, path, viewBox, strokeWidth, reverse } = this.props;

    return (
      <svg
        ref="svg"
        className={`path-reveal`}
        viewBox={viewBox || '0 0 100 100'}
        preserveAspectRatio="none">
        <path
          ref="marchPath"
          stroke="red"
          strokeWidth={strokeWidth || 5}
          strokeLinecap="round"
          fill="none"
          d={path}
        />
      </svg>
    );
  }
};

PathReveal.propTypes = {
  constant: PropTypes.number.isRequired,
  path: PropTypes.string.isRequired,
  position: PropTypes.number.isRequired
};

const animation = ({context, timeline}) => {
  const { marchPath, svg } = context.refs;
  const { usePixels, constant, reverse } = context.props
  const direction = reverse ? -1 : 1;
  const offset = constant * direction

  TweenMax.set(marchPath, {
    css: {
      strokeDasharray: `${constant}${usePixels ? 'px' : '%'}`,
      strokeDashoffset: `${constant}${usePixels ? 'px' : '%'}`
    }
  });

  timeline.to(marchPath, 1, { css: { strokeDashoffset: `${reverse ? constant * 2 : 0}${usePixels ? 'px' : '%'}`}, ease: Linear.easeNone });
};

export default timeline(PathReveal, { animation });
