import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';

import {Image} from 'components/Image';
import timeline from 'components/timelineComponent';
import scene from 'components/scene';
import {tabbable} from 'utils/accessibility'

import CenterView from 'templates/CenterView';

import './introduction-scene-2.scss';

import {
  hideAllRefs, showElemWithAria, hideElementWithAria, hideFromBelow, revealFromBelow
} from 'utils/animation';


class IntroS2 extends React.Component {
  render() {
    const {
      freeCitizens,
      iconFreeSpeech,
      iconFreePress,
      iconFreeAssembly,
      studentsSit,
    } = this.props.data;

    return (
      <div className="introduction-scene-2">
        <div className="speech-press-assembly" ref="speechPressAssembly">
          <CenterView background={(
            <div className="introduction-scene-2__image" ref="speechPressAssemblyBackground">
              <Image
                className="speech-press-assembly__background"
                image={freeCitizens}
                alt="Free citizens"
              />
            </div>
          )} content={(
            <div ref="speechPressAssemblyContent">
              <p {...tabbable} ref="text" className="speech-press-assembly__text">The story of the historic voting rights movement in Selma, Alabama, in the mid-1960s, illustrates how Americans seized upon the rights guaranteed to them in the Constitution to challenge injustice.</p>
              <div className="speech-press-assembly__container">
                <div ref="item1" className="speech-press-assembly__item">
                  <Image image={iconFreeSpeech} />
                  <p className="highlight alt">freedom of</p>
                  <p className="freedom-name">Speech</p>
                </div>
                <div ref="item2" className="speech-press-assembly__item">
                  <Image image={iconFreePress} />
                  <p className="highlight alt">freedom of</p>
                  <p className="freedom-name">Press</p>
                </div>
                <div ref="item3" className="speech-press-assembly__item">
                  <Image image={iconFreeAssembly} />
                  <p className="highlight alt">freedom of</p>
                  <p className="freedom-name">Assembly</p>
                </div>
              </div>
            </div>
          )} />
        </div>
        <div className="students-sit" ref="studentsSit">
          <CenterView background={(
            <div className="introduction-scene-2__image" ref="studentsSitBackground">
              <Image className="students-sit__background" image={studentsSit} />
            </div>
          )} content={(
            <div {...tabbable} ref="studentsSitContent">
              <p ref="studentsSitText1" className="students-sit__text">The successful Voting Rights campaign at Selma</p>
              <p ref="studentsSitText2" className="students-sit__text">showed <span className="highlight">the power of activism</span>,</p>
              <p ref="studentsSitText3" className="students-sit__text">underscored the importance of <span className="highlight">moral leadership</span>,</p>
              <p ref="studentsSitText4" className="students-sit__text">
                and demonstrated how the energy and momentum for
                <span className="highlight"> social change</span> often comes from the youngest among us.
              </p>
            </div>
          )} />
        </div>
      </div>
    );
  }
}

const animation = ({context, timeline, easeOut}) => {

  const {
    speechPressAssembly,
    speechPressAssemblyContent,
    speechPressAssemblyBackground,
    studentsSit,
    studentsSitContent,
    text,
    item1,
    item2,
    item3,
    studentsSitText1,
    studentsSitText2,
    studentsSitText3,
    studentsSitText4,
  } = context.refs;

  hideAllRefs(context.refs, [
    'speechPressAssembly',
    'speechPressAssemblyBackground',
    'studentsSitBackground',
    'studentsSitContent',
    'speechPressAssemblyContent'
  ]);

  TweenMax.set([
    text,
    item1,
    item2,
    item3,
    studentsSitText1,
    studentsSitText2,
    studentsSitText3,
    studentsSitText4
  ], {...hideFromBelow})

  timeline.to(speechPressAssemblyBackground, 1, {autoAlpha: 0.2, ease: Linear.easeNone});
  timeline.to(text, 1.5, {...revealFromBelow, ...easeOut}, "-=0.5")
  timeline.to(item1, 1.5, {...revealFromBelow, ...easeOut}, "-=1.25")
  timeline.to(item2, 1.5, {...revealFromBelow, ...easeOut}, "-=1.25")
  timeline.to(item3, 1.5, {...revealFromBelow, ...easeOut}, "-=1.25")

  timeline.markers.createTimelineMarker();

  timeline.to([speechPressAssemblyContent,speechPressAssemblyBackground, speechPressAssembly], 1, {...hideElementWithAria});

  timeline.to(studentsSit, 1, {...showElemWithAria}, '-=0.5');
  timeline.to(studentsSitText1, 1.5, {...revealFromBelow, ...easeOut}, "-=0.5")
  timeline.to(studentsSitText2, 1.5, {...revealFromBelow, ...easeOut}, "-=1.25")
  timeline.to(studentsSitText3, 1.5, {...revealFromBelow, ...easeOut}, "-=1.25")
  timeline.to(studentsSitText4, 1.5, {...revealFromBelow, ...easeOut}, "-=1.25")

  timeline.markers.createTimelineMarker();

  ;
};

const query = graphql`
  query {
    freeCitizens: file(
      relativePath: { eq: "introduction/scene-2/images/intro-image-free-citizens@2x.jpg" }
    ) {
      ...largeImage
    }
    iconFreeSpeech: file(
      relativePath: { eq: "introduction/scene-2/images/icon-free-speech@2x.png" }
    ) {
      ...doodleImage
    }
    iconFreePress: file(
      relativePath: { eq: "introduction/scene-2/images/icon-free-press@2x.png" }
    ) {
      ...doodleImage
    }
    iconFreeAssembly: file(
      relativePath: { eq: "introduction/scene-2/images/icon-free-assembly@2x.png" }
    ) {
      ...doodleImage
    }
    studentsSit: file(
      relativePath: { eq: "introduction/scene-2/images/intro-image-students-sit@2x.png" }
    ) {
      ...largeImage
    }
  }
`;

const SceneComponent = scene(timeline(IntroS2, {animation}));
export default (props) => {
  const data = useStaticQuery(query);
  return <SceneComponent data={data} {...props} />;
};
