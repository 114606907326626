import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';

import {Image} from 'components/Image';
import timeline from 'components/timelineComponent';
import scene from 'components/scene';
import Modal from 'components/ChapterModal';

import CenterView from 'templates/CenterView';
import DemocracyModal from './DemocracyModal';

import './introduction-scene-3.scss';
import {hideAllRefs, placeholder, showElemWithAria, hideElementWithAria, hideFromBelow, revealFromBelow} from 'utils/animation';
import {tabbable} from 'utils/accessibility'


class IntroS3 extends React.Component {
  render() {
    const {ballotbox} = this.props.data;

    return (
      <div className="voting-equal-scene-3">
        <div className="ballot-box" ref="ballotBox">
          <CenterView
            background={
              <div ref="ballotBoxForeground">
                <Image className="voting-equal-scene-3__image" image={ballotbox} alt="Ballotbox"/>
              </div>
            }
            content={
              <div
                className="voting-equal-scene-3__text"
                ref="ballotBoxContent">
                <p ref="ballotBoxContentAbove" {...tabbable}>
                  Above all, it proves what is most essential in a democracy:
                </p>
                <div>
                  <div {...tabbable} aria-label="Voting is the necessary precondition for full and equal citizenship." className='screen-reader-text'></div>
                  <h1 className="highlight display" ref="ballotBoxContentVoting">
                    VOTING

                    <div
                      className="modal-container"
                      ref="democracyModal">
                      <Modal
                        className="chapter-1-scene-2__modal">
                        <DemocracyModal />
                      </Modal>
                    </div>
                  </h1>
                  <h2 ref="ballotBoxContentPrecondition">
                    is the necessary precondition
                    <br />
                    for full and equal citizenship.
                  </h2>
                </div>
              </div>
            }
          />
        </div>
      </div>
    );
  }
}

const animation = ({context, timeline, easeOut}) => {

  const {
    ballotBox,
    ballotBoxContentAbove,
    ballotBoxContentVoting,
    ballotBoxContentPrecondition,
    ballotBoxForeground,
    democracyModal
  } = context.refs;

  hideAllRefs(context.refs, [
    'ballotBox',
    'ballotBoxContent',
    'ballotBoxForeground',
  ]);

  TweenMax.set([ballotBoxContentAbove,ballotBoxContentVoting,ballotBoxContentPrecondition], { ...hideFromBelow });

  timeline.to(ballotBoxContentAbove, 1.5, { ...revealFromBelow, ...easeOut });
  timeline.to(ballotBoxForeground, 1, { autoAlpha: 0.3 }, '-=1.5');

  timeline.to(
    ballotBoxContentVoting,
    1.5,
    { ...revealFromBelow, ...easeOut },
  );

  timeline.to(democracyModal, 1, {...showElemWithAria},"-=1.5");

  timeline.to(
    ballotBoxContentPrecondition,
    1.5,
    { ...revealFromBelow, ...easeOut },
    '-=1.25'
  );

  timeline.markers.createTimelineMarker();

  timeline.to(ballotBox, 0.5, { ...hideElementWithAria });

  ;
};

const query = graphql`
  query {
    ballotbox: file(
      relativePath: { eq: "introduction/scene-3/images/ch-0-image-ballotbox@2x.png" }
    ) {
      ...largeImage
    }
  }
`;

const SceneComponent = scene(timeline(IntroS3, {animation}), {stopAtEnd: false});
export default (props) => {
  const data = useStaticQuery(query);
  return <SceneComponent data={data} {...props} />;
};
